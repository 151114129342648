import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss']
})
export class ContactSupportComponent implements OnInit {
  customerDetails: FormGroup;
  ticketSubmitted: boolean;
  ticketSubmissionFailed: boolean;

  constructor() {}

  ngOnInit() {
    this.customerDetails = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.email, Validators.required]),
      phone: new FormControl('', [Validators.required]),
      company: new FormControl('', Validators.required)
    });
  }

  get name() {
    return this.customerDetails.get('name');
  }

  get email() {
    return this.customerDetails.get('email');
  }

  get phone() {
    return this.customerDetails.get('phone');
  }

  get company() {
    return this.customerDetails.get('company');
  }

  onSubmit() {
    // Implementation pending

    this.ticketSubmissionFailed = false;
    this.ticketSubmitted = true;
  }
}
