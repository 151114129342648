import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authService.currentUserValue;
    if (currentUser && currentUser.token) {
      let headers: HttpHeaders;
      headers = request.headers.set('Authtoken', `${currentUser.token}`);
      if (currentUser.initialCompanyId && currentUser.initialCompanyId !== currentUser.companyId) {
        headers = headers.set('operatorCompanyId', `${currentUser.companyId}`);
      }
      request = request.clone({ headers });
    }

    return next.handle(request);
  }
}
