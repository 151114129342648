import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
export class PendoService {
    constructor(document) {
        this.document = document;
    }
    loadPendo(apiKey, userWrapper) {
        // tslint:disable: no-string-literal
        const o = (window['pendo'] = window['pendo'] || {});
        o._q = o._q || [];
        const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (let w = 0, x = v.length; w < x; ++w) {
            // tslint:disable: prefer-const
            let m = v[w];
            // tslint:disable: only-arrow-functions
            o[m] =
                o[m] ||
                    function () {
                        o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
                    };
        }
        const y = this.document.createElement('script');
        y.async = !0;
        y.src = 'https://cdn.eu.pendo.io/agent/static/' + apiKey + '/pendo.js';
        const z = this.document.getElementsByTagName('script')[0];
        z.parentNode.insertBefore(y, z);
        this.initPendo(userWrapper);
    }
    initPendo(userWrapper) {
        const user = userWrapper.user;
        const tenantType = userWrapper.isMSPTenant ? 'MSP' : 'Metallic';
        pendo.initialize({
            visitor: {
                id: user.userGuid,
                email: user.email,
                full_name: user.userName,
                // role:         // Optional
                // You can add any additional visitor level key-values here,
                // as long as it's not one of the above reserved names.
                tenant_type: tenantType
            },
            account: {
                id: user.companyGuid,
                name: user.companyName,
                // is_paying:    // Recommended if using Pendo Feedback
                // monthly_value:// Recommended if using Pendo Feedback
                // planLevel:    // Optional
                // planPrice:    // Optional
                // creationDate: // Optional
                // You can add any additional account level key-values here,
                // as long as it's not one of the above reserved names.
                ring_id: window.location.host,
                tenant_type: tenantType
            }
        });
    }
}
PendoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PendoService_Factory() { return new PendoService(i0.ɵɵinject(i1.DOCUMENT)); }, token: PendoService, providedIn: "root" });
