import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageMaskService {
  loadingSubject = new BehaviorSubject<boolean>(false);
  progressMsgSubject = new BehaviorSubject<string>('');

  constructor() {}

  mask(msg?: string) {
    this.loadingSubject.next(true);
    if (msg) {
      this.progressMsgSubject.next(msg);
    }
  }

  unmask() {
    this.loadingSubject.next(false);
    this.progressMsgSubject.next('');
  }

  setProgressMsg(msg: string) {
    this.progressMsgSubject.next(msg);
  }
}
