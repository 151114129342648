// client side values for offering
export enum OfferingType {
  COMPUTE = 'compute',
  DATABASE = 'db',
  FILE_OBJECT = 'file',
  O365 = 'o365',
  ENDPOINT = 'endpoint',
  SALESFORCE = 'salesforce',
  D365 = 'd365',
  ACTIVE_DIRECTORY = 'ad',
  MAAS = 'maas',
  VM_STANDARD = 'vm-standard',
  VM_ADVANCED = 'vm-advanced',
  VM_ENTERPRISE = 'vm-enterprise',
  THREATWISE = 'threatwise',
  FILE_OBJECT_ARCHIVE = 'file-object-archive',
  // not really an offering; but keeping it here as we are showing under service catalog
  SECURITY_IQ = 'security-iq',
  SERVICE_CATALOG = 'service-catalog',
  USAGE_SUMMARY = 'usage-summary',
  MIRAGE = 'mirage',
  // not really an offering; but keeping it here as we need the subscription details
  MCS_HOT = 'mcss-hot',
  MCS_COOL = 'mcss-cool',
  MCS_OCI_STANDARD = 'mcs-oci-standard',
  MCS_OCI_INFREQUENT = 'mcs-oci-infrequent',
  O365_STANDARD = 'o365-standard',
  O365_ENTERPRISE = 'o365-enterprise'
}

// offering names that are being returned by the backend for the trial limit
export const TrialLimitOfferingType = {
  HYBRID: 'hybrid',
  MRR_AZURE_HOT: 'mrr-azure-hot',
  MRR_AZURE_COOL: 'mrr-azure-cool',
  MRR_OCI_STANDARD: 'mrr-oci-standard',
  MRR_OCI_INFREQUENT: 'mrr-oci-infrequent'
};

// server side values for offering
export enum OfferingServerType {
  COMPUTE = 'Metallic VM & Kubernetes',
  DATABASE = 'Metallic Database',
  FILE_OBJECT = 'Metallic File & Object',
  O365 = 'Metallic Office 365',
  ENDPOINT = 'Metallic Endpoint',
  STORAGE = 'Metallic Storage',
  SALESFORCE = 'Metallic Salesforce',
  D365 = 'Metallic Dynamics',
  MCS_HOT = 'MCSS Hot',
  MCS_COOL = 'MCSS Cool',
  ACTIVE_DIRECTORY = 'Metallic Active Directory',
  MAAS = 'Metallic Migration as a Service',
  VM_STANDARD = 'Metallic VM & Kubernetes Backup - Standard',
  VM_ADVANCED = 'Metallic VM & Kubernetes Backup - Advanced',
  VM_ENTERPRISE = 'Metallic VM & Kubernetes Backup - Enterprise',
  THREATWISE = 'Metallic ThreatWise',
  MCS_OCI_STANDARD = 'Metallic Recovery Reserve - OCI Standard',
  MCS_OCI_INFREQUENT = 'Metallic Recovery Reserve - OCI Infrequent Access',
  FILE_OBJECT_ARCHIVE = 'Metallic File & Object Archive',
  O365_STANDARD = 'Metallic Office 365 - Standard',
  O365_ENTERPRISE = 'Metallic Office 365 - Enterprise'
}
export class OfferingMapping {
  private offeringMap: any = {};
  constructor() {
    for (const offering of Object.keys(OfferingType)) {
      this.offeringMap[OfferingType[offering]] = OfferingServerType[offering];
    }
  }
  getServerType(type: OfferingType): OfferingServerType {
    return this.offeringMap[type];
  }
}
const OFFERING_MAP: OfferingMapping = new OfferingMapping();

// Refer: https://dev.azure.com/turinnbi/ProjectTurin/_git/BusinessSystems?path=%2FBusinessSystem%2F
// BusinessSystem%2FConstants.cs&version=GBdev&line=63&lineEnd=64&lineStartColumn=1&lineEndColumn=1&lineStyle=plain&_a=contents

export class OfferingProductIdMapping {
  private offeringProductMap: any = {};
  constructor() {
    this.offeringProductMap[OfferingType.COMPUTE] = 8;
    this.offeringProductMap[OfferingType.DATABASE] = 9;
    this.offeringProductMap[OfferingType.FILE_OBJECT] = 10;
    this.offeringProductMap[OfferingType.O365] = 4;
    this.offeringProductMap[OfferingType.ENDPOINT] = 5;
    this.offeringProductMap[OfferingType.SALESFORCE] = 11;
    this.offeringProductMap[OfferingType.D365] = 12;
    this.offeringProductMap[OfferingType.MCS_HOT] = 13;
    this.offeringProductMap[OfferingType.MCS_COOL] = 14;
    this.offeringProductMap[OfferingType.ACTIVE_DIRECTORY] = 15;
    this.offeringProductMap[OfferingType.VM_STANDARD] = 17;
    this.offeringProductMap[OfferingType.VM_ADVANCED] = 18;
    this.offeringProductMap[OfferingType.VM_ENTERPRISE] = 19;
    this.offeringProductMap[OfferingType.THREATWISE] = 20;
    this.offeringProductMap[OfferingType.FILE_OBJECT_ARCHIVE] = 21;
    this.offeringProductMap[OfferingType.MCS_OCI_STANDARD] = 22;
    this.offeringProductMap[OfferingType.MCS_OCI_INFREQUENT] = 23;
    this.offeringProductMap[OfferingType.O365_STANDARD] = 24;
    this.offeringProductMap[OfferingType.O365_ENTERPRISE] = 25;
  }
  getProductType(type: OfferingType): number {
    return this.offeringProductMap[type];
  }
}
const OFFERING_PRODUCT_ID_MAP: OfferingProductIdMapping = new OfferingProductIdMapping();

// client side values for features
export enum FeatureType {
  // Compute features
  VMWARE = 'vmware',
  AZUREVM = 'azurevm',
  KUBERNETES = 'kubernetes',
  HYPERV = 'hyperv',
  AWS_EC2 = 'aws-ec2',
  OCI_VM = 'oci-vm',
  NUTANIX_AHV = 'nutanix-ahv',

  // File & Object storage
  FILE_SYSTEM = 'fs',
  OBJECT_STORAGE = 'object-storage',
  AWS_S3 = 'aws-s3', // To check the feature support
  OCI_OBJECT_STORAGE = 'oci-os', // To check the feature support

  // Database
  AWS_DB = 'aws-db',
  SQL_SERVER = 'sql',
  SAP_HANA = 'sap-hana',
  ORACLE = 'oracle',
  MICROSOFT_AD = 'microsoft-ad',
  Azure_DB = 'azure-db',
  // Office365
  EXCHANGE = 'exchange',
  SHAREPOINT = 'sharepoint',
  ONEDRIVE = 'onedrive',
  TEAMS = 'teams',
  // Endpoint
  ENDPOINT = 'endpoint',
  // Salesforce
  SALESFORCE = 'salesforce',
  // Dynamics 365
  D365 = 'd365',
  // Active Directory
  AZURE_AD = 'azure-ad',

  SALESFORCE_AZURE = 'salesforce-azure', // not yet implemented
  O365_BYOS = 'o365-byos',

  // Migrartion as service
  MAAS = 'maas',

  AZURE_MAAS = 'azure-maas', // not yet implemented

  AWS_WORKLOAD = 'aws-workload', // To get the AWS supported regions

  OCI_WORKLOAD = 'oci-workload', // To get the OCI supported regions

  ORACLE_RAC = 'oracle-rac'
}

// server side values for features(used in entities API)
export enum FeatureServerType {
  // Compute features
  VMWARE = 'vmware',
  AZUREVM = 'azurevm',
  KUBERNETES = 'kubernetes',
  HYPERV = 'hyperv',
  AWS_EC2 = 'aws-ec2',
  OCI_VM = 'oci-vm',
  NUTANIX_AHV = 'nutanix-ahv',

  // Database
  AWS_DB = 'aws',
  SQL_SERVER = 'sqlServer',
  SAP_HANA = 'sapHana',
  ORACLE = 'oracle',
  MICROSOFT_AD = 'microsoftAD',
  Azure_DB = 'azure',
  // File & Object storage
  FILE_SYSTEM = 'fileServer',
  OBJECT_STORAGE = 'objectStorage',

  // Office365
  EXCHANGE = 'mailBoxes',
  SHAREPOINT = 'sharepointSites',
  ONEDRIVE = 'oneDrive',
  TEAMS = 'teams',
  // Endpoint
  ENDPOINT = 'endpoint',
  // SalesForce
  SALESFORCE = 'salesforce',
  // Active Directory
  AZURE_AD = 'azureAD'
}

export class FeatureMapping {
  private featureMap: any = {};
  constructor() {
    // compute features
    this.featureMap[FeatureType.VMWARE] = FeatureServerType.VMWARE;
    this.featureMap[FeatureType.HYPERV] = FeatureServerType.HYPERV;
    this.featureMap[FeatureType.AZUREVM] = FeatureServerType.AZUREVM;
    this.featureMap[FeatureType.KUBERNETES] = FeatureServerType.KUBERNETES;
    this.featureMap[FeatureType.AWS_EC2] = FeatureServerType.AWS_EC2;
    this.featureMap[FeatureType.OCI_VM] = FeatureServerType.OCI_VM;
    this.featureMap[FeatureType.NUTANIX_AHV] = FeatureServerType.NUTANIX_AHV;
    // database features
    this.featureMap[FeatureType.AWS_DB] = FeatureServerType.AWS_DB;
    this.featureMap[FeatureType.SQL_SERVER] = FeatureServerType.SQL_SERVER;
    this.featureMap[FeatureType.SAP_HANA] = FeatureServerType.SAP_HANA;
    this.featureMap[FeatureType.ORACLE] = FeatureServerType.ORACLE;
    this.featureMap[FeatureType.MICROSOFT_AD] = FeatureServerType.MICROSOFT_AD;
    this.featureMap[FeatureType.Azure_DB] = FeatureServerType.Azure_DB;
    // file & object storage
    this.featureMap[FeatureType.FILE_SYSTEM] = FeatureServerType.FILE_SYSTEM;
    this.featureMap[FeatureType.OBJECT_STORAGE] = FeatureServerType.OBJECT_STORAGE;
    // o365 Fearures : the keys will be used only for data source and not for sla
    this.featureMap[FeatureType.EXCHANGE] = FeatureServerType.EXCHANGE;
    this.featureMap[FeatureType.SHAREPOINT] = FeatureServerType.SHAREPOINT;
    this.featureMap[FeatureType.ONEDRIVE] = FeatureServerType.ONEDRIVE;
    this.featureMap[FeatureType.TEAMS] = FeatureServerType.TEAMS;
    // Active Directory
    this.featureMap[FeatureType.AZURE_AD] = FeatureServerType.AZURE_AD;

    // For Core, SLA and datasources are part of single entities API
  }
  getServerType(type: FeatureType): FeatureServerType {
    return this.featureMap[type];
  }
}

const FEATURE_MAP: FeatureMapping = new FeatureMapping();

export class OfferingWrapper {
  isSolutionsResolved = false;
  isSubscriptionResolved = false;
  offerings: Array<Offering>;
  mspStorageUsage: SubscriptionUsage;
  mcsUsage: Map<number, SubscriptionUsage>;
  tenantId: string;
  disableTrials = false;
}

export class Offering {
  name: OfferingType;
  type: OfferingServerType;
  productId: number;
  displayName: string;
  isConfigured = false;
  usage: SubscriptionUsage;
  detail: SubscriptionDetail;
  hasEDiscovery? = false;
  isEDiscoveryProvisioned? = false;
  icon? = '';
  features: Feature[];
  featuresDisplayText? = '';
  isNewOffering = false;
  storageDetail: StorageDetail;
  catalogDisplayName = '';
  isLegacySaaSAccount = false;
  isMockOffering = false; // flag to identify if this offering is not really an offering in SFDC. e.g. Security IQ

  constructor(name: OfferingType, features: Feature[], newOffering = false) {
    this.name = name;
    this.type = OFFERING_MAP.getServerType(name);
    this.productId = OFFERING_PRODUCT_ID_MAP.getProductType(name);
    this.features = features;
    this.isNewOffering = newOffering;
  }
}

export class FeatureLink {
  path: string;
  queryParams: any;
  // if the link is redirect to Admin console, then set this flag to true
  isACRedirect = false;

  constructor(path: string, queryParams?: any, isACRedirect: boolean = false) {
    this.path = path;
    this.queryParams = queryParams;
    this.isACRedirect = isACRedirect;
  }
}

export class StorageDetail {
  useBYOS: boolean;
  byosProcessingCompleted: boolean;

  constructor(useBYOS: boolean, byosprocessingCompleted: boolean) {
    this.useBYOS = useBYOS;
    this.byosProcessingCompleted = byosprocessingCompleted;
  }
}

export class Feature {
  type: FeatureType;
  serverType: string;
  displayName: string;
  icon: string;
  link: FeatureLink;
  isSupported?: boolean;

  constructor(type: FeatureType, isSupported: boolean, link?: FeatureLink) {
    this.type = type;
    this.serverType = FEATURE_MAP.getServerType(type);
    this.isSupported = isSupported;
    if (link) {
      this.link = link;
    }
  }
}

export class SubscriptionUsage {
  purchased: number;
  used: number;
  overage: number;
  unit: UsageUnit;
  tier: string;

  constructor(purchased: number, used: number, unit: UsageUnit, tier: string) {
    this.purchased = purchased;
    this.used = used;
    this.overage = Math.max(this.used - this.purchased, 0);
    this.unit = unit;
    this.tier = tier;
  }
}

export enum UsageUnit {
  SIZE_GB = 'size_db',
  SIZE_TB = 'size_tb',
  COUNT_VM = 'count_vm',
  COUNT_USERS = 'count_users'
}

export enum SubscriptionType {
  TRIAL = 'Trial',
  PAID = 'Paid'
}

export enum TierType {
  COMMIT = 'C',
  NON_COMMIT = 'NC'
}

export enum TrialAccessStatus {
  NA = 'Not Applicable',
  REQUESTED = 'Requested',
  APPROVED = 'Approved (Pending)',
  GRANTED = 'Granted',
  DENIED = 'Denied'
}

export enum ProvisioningStatus {
  NA = 'Not Applicable',
  PENDING = 'Pending',
  COMPLETED = 'Completed'
}

export enum OfferStatus {
  IN_TRIAL = 'in-trial',
  EXPIRED = 'trial-expired',
  REQUESTED = 'trial-requested',
  APPROVED = 'trial-approved',
  GRANTED = 'in-trial',
  DENIED = 'trial-denied'
}

export class SubscriptionDetail {
  type: SubscriptionType;
  trialStartDate: string;
  trialEndDate: string;
  isTrialExpired: boolean;
  isPaid: boolean;
  trialDaysLeft: number;
  trialHoursLeft: number;
  trialAccessStatus: TrialAccessStatus;
  provisioningStatus: ProvisioningStatus; // Currently, Contains the provisioning status of FOA / AAAS
  tsocUrl: string;

  constructor(type: SubscriptionType) {
    this.type = type;
    this.isPaid = type === SubscriptionType.PAID;
  }
}
