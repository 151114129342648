import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { UserWrapper } from '../datamodel/user';

declare let pendo: any;

@Injectable({
  providedIn: 'root'
})
export class PendoService {
  constructor(@Inject(DOCUMENT) private document: any) {}

  loadPendo(apiKey: string, userWrapper: UserWrapper) {
    // tslint:disable: no-string-literal
    const o = (window['pendo'] = window['pendo'] || {});
    o._q = o._q || [];
    const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (let w = 0, x = v.length; w < x; ++w) {
      // tslint:disable: prefer-const
      let m = v[w];
      // tslint:disable: only-arrow-functions
      o[m] =
        o[m] ||
        function () {
          o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
        };
    }
    const y = this.document.createElement('script');
    y.async = !0;
    y.src = 'https://cdn.eu.pendo.io/agent/static/' + apiKey + '/pendo.js';
    const z = this.document.getElementsByTagName('script')[0];
    z.parentNode.insertBefore(y, z);
    this.initPendo(userWrapper);
  }

  initPendo(userWrapper: UserWrapper) {
    const user = userWrapper.user;
    const tenantType = userWrapper.isMSPTenant ? 'MSP' : 'Metallic';
    pendo.initialize({
      visitor: {
        id: user.userGuid, // Required if user is logged in
        email: user.email, // Recommended if using Pendo Feedback, or NPS Email
        full_name: user.userName, // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
        tenant_type: tenantType
      },

      account: {
        id: user.companyGuid, // Highly recommended
        name: user.companyName, // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
        ring_id: window.location.host,
        tenant_type: tenantType
      }
    });
  }
}
