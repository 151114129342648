/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./core/header/header.component.ngfactory";
import * as i3 from "./core/header/header.component";
import * as i4 from "./core/auth/auth.service";
import * as i5 from "@angular/router";
import * as i6 from "./core/branding/branding.service";
import * as i7 from "@angular/common";
import * as i8 from "./core/page-loading/page-loading.component.ngfactory";
import * as i9 from "./core/page-loading/page-loading.component";
import * as i10 from "./translations/translations.component.ngfactory";
import * as i11 from "./translations/translations.component";
import * as i12 from "./translations/translations.service";
import * as i13 from "./core/page-mask/page-mask.component.ngfactory";
import * as i14 from "./core/page-mask/page-mask.component";
import * as i15 from "./core/page-mask/page-mask.service";
import * as i16 from "./core/chat/offline-chat/offline-chat.component.ngfactory";
import * as i17 from "./core/chat/offline-chat/offline-chat.component";
import * as i18 from "./core/chat/chat.service";
import * as i19 from "./app.component";
import * as i20 from "@angular/platform-browser";
import * as i21 from "./app.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i3.HeaderComponent, [i4.AuthService, i5.ActivatedRoute, i6.BrandingService, i7.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-loading", [], null, null, null, i8.View_PageLoadingComponent_0, i8.RenderType_PageLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i9.PageLoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-translations", [], null, null, null, i10.View_TranslationsComponent_0, i10.RenderType_TranslationsComponent)), i1.ɵdid(3, 4308992, null, 0, i11.TranslationsComponent, [i12.TranslationsService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 5, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "container mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(9, 212992, [["appRouter", 4]], 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "page-mask", [], null, null, null, i13.View_PageMaskComponent_0, i13.RenderType_PageMaskComponent)), i1.ɵdid(11, 114688, null, 0, i14.PageMaskComponent, [i15.PageMaskService], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "offline-chat", [], null, null, null, i16.View_OfflineChatComponent_0, i16.RenderType_OfflineChatComponent)), i1.ɵdid(13, 114688, null, 0, i17.OfflineChatComponent, [i5.Router, i18.ChatService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.user && _co.isAppInitialized); _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); var currVal_1 = (!i1.ɵnov(_v, 9).activatedRouteData.isPublicUrl && !(_co.user && _co.isAppInitialized)); _ck(_v, 7, 0, currVal_1); _ck(_v, 9, 0); _ck(_v, 11, 0); _ck(_v, 13, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i19.AppComponent, [i20.Title, i4.AuthService, i21.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i19.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
