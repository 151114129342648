import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TranslateDirective } from './translate.directive';
import { TranslationsService } from './translations.service';

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss']
})
export class TranslationsComponent implements OnInit, AfterViewInit {
  @ViewChildren(TranslateDirective, { read: ElementRef }) translateElems: QueryList<ElementRef>;

  constructor(private translateService: TranslationsService) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.translateElems.map((eachTrans: any) => {
      this.translateService.set(eachTrans.nativeElement.dataset.key, eachTrans.nativeElement.innerHTML);
    });
    // this.translateService.log();
  }
}
