import { DOCUMENT } from '@angular/common';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Inject, Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Customization } from '../../core/datamodel/customization';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {
  oem = environment.oem;
  private currentTheme = {
    isResolved: false,
    logoPath: `assets/brand/${this.oem}/logo.svg`,
    iconPath: `assets/brand/${this.oem}/favicon.png`
  };
  themeSubject = new BehaviorSubject<any>(this.currentTheme);
  customization: Customization;
  constructor(
    private httpCodec: HttpUrlEncodingCodec,
    @Inject(DOCUMENT) private document: Document,
    private sanitizer: DomSanitizer
  ) {}

  getFavIcon() {
    return this.currentTheme.iconPath;
  }

  init(customizaton: any) {
    if (
      customizaton &&
      customizaton.organizationInfo &&
      customizaton.organizationInfo.organizationProperties &&
      customizaton.organizationInfo.organizationProperties.customization
    ) {
      this.customization = JSON.parse(
        customizaton.organizationInfo.organizationProperties.customization
      ) as Customization;
      this.setCustomTheme();
    }
    this.currentTheme.isResolved = true;
    this.themeSubject.next(this.currentTheme);
  }

  resolveTheme() {
    this.currentTheme.isResolved = true;
    this.themeSubject.next(this.currentTheme);
  }

  setCustomTheme() {
    const head = this.document.getElementsByTagName('head')[0];
    const customTheme = this.document.createElement('link');
    customTheme.rel = 'stylesheet';
    customTheme.href = 'assets/css/custom-color-theme.css?' + environment.currentTimeStamp;
    this.setIcons();
    this.setBackgroundColor();
    head.appendChild(customTheme);
    this.setCustomCss(head);
  }

  setIcons() {
    if (this.customization.customLogoSmall) {
      this.currentTheme.logoPath = this.sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/' + this.customization.customLogoSmall
      ) as string;
    }
    if (this.customization.customFavicon) {
      this.currentTheme.iconPath = this.sanitizer.sanitize(
        SecurityContext.URL,
        this.sanitizer.bypassSecurityTrustResourceUrl('data:image/' + this.customization.customFavicon)
      );
    }
  }

  setBackgroundColor() {
    if (this.customization.actionBtnBg && this.customization.actionBtnBg !== '') {
      this.document.documentElement.style.setProperty('--button-primary', this.customization.actionBtnBg);
    }

    if (this.customization.actionBtnText && this.customization.actionBtnText !== '') {
      this.document.documentElement.style.setProperty('--button-text', this.customization.actionBtnText);
    }

    if (this.customization.linkText && this.customization.actionBtnText !== '') {
      this.document.documentElement.style.setProperty('--link-text', this.customization.linkText);
    }

    if (this.customization.loginAndBannerBg && this.customization.loginAndBannerBg !== '') {
      this.document.documentElement.style.setProperty('--banner-background', this.customization.loginAndBannerBg);
    }

    if (this.customization.navBg && this.customization.navBg !== '') {
      this.document.documentElement.style.setProperty('--navigation-bar-background', this.customization.navBg);
    }
  }

  setCustomCss(head: HTMLHeadElement) {
    if (this.customization.customizeCSS && this.customization.customizeCSS !== '') {
      const customCssString = this.httpCodec.decodeValue(this.customization.customizeCSS);
      const style = document.createElement('style');
      style.id = 'custom-hub';
      style.textContent = customCssString;
      head.append(style);
    }
  }
}
