import { HttpClientModule, HTTP_INTERCEPTORS, HttpUrlEncodingCodec } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from './app.component';
import { routes, mcRoutes } from './app.routes';
import { AuthErrorInterceptor } from './core/auth/auth-error.interceptor';
import { AuthTokenInterceptor } from './core/auth/auth-token.interceptor';
import { CoreModule } from './core/core.module';
import { TranslationsComponent } from './translations/translations.component';
import { environment } from 'src/environments/environment';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { HttpErrorInterceptor } from './error-handling/http-error.interceptor';
import { GlobalErrorHandler } from './error-handling/global-error-handler';
import { JoyrideModule } from 'ngx-joyride';
import { registerLocaleData } from '@angular/common';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import en from '@angular/common/locales/en';
import { LoggingService } from './logging/logging.service';

registerLocaleData(en);

@NgModule({
  declarations: [AppComponent, TranslationsComponent],
  imports: [
    // Angular Modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(environment.multiCommcellDeployment ? mcRoutes : routes, {
      useHash: true,
      enableTracing: false
    }),
    // App Modules
    CoreModule,
    // tour module
    JoyrideModule.forRoot(),
    // Logging
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG, disableConsoleLogging: environment.production }),
    NgZorroAntdModule
  ],
  providers: [
    CookieService,
    LoggingService,
    HttpUrlEncodingCodec,
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
