import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { get } from 'lodash';
import { TranslationsService } from 'src/app/translations/translations.service';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private translateService: TranslationsService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        if (get(err, 'status', 0) === 401) {
          // re-login if 401 Unauthorized response returned from api
          this.authService.relogin();
          return throwError(err);
        } else {
          const errorMsg =
            get(err, 'error.error_message') ||
            get(err, 'error.errorMessage') ||
            get(err, 'errorMessage', this.translateService.get('general-error'));
          return throwError(errorMsg);
        }
      })
    );
  }
}
