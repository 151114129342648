import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  durationObject: any[] = [
    {
      unit: 'y',
      factor: 12,
      name: 'year'
    },
    {
      unit: 'M',
      factor: 30,
      name: 'month'
    },
    {
      unit: 'd',
      factor: 24,
      name: 'day'
    },
    {
      unit: 'h',
      factor: 60,
      name: 'hour'
    },
    {
      unit: 'm',
      factor: 60,
      name: 'minute'
    },
    {
      unit: 's',
      factor: 1000,
      name: 'second'
    }
  ];

  singleUnitDisplayName: any = {
    hour: 'Hourly',
    day: 'Daily',
    month: 'Monthly',
    year: 'Yearly'
  };

  transform(
    value: any,
    srcUnit: string = 'ms',
    enableSinglUnitDisplay: boolean = false,
    shortName: boolean = false
  ): any {
    // If the value is -1 then it should be Infinite
    if (value === -1) {
      return 'Infinite';
    }
    const destUnit = 'relative'; // supporting only relative for now
    const srcIndex = this.durationObject.findIndex((eachUnit) => eachUnit.unit === srcUnit);
    const destIndex =
      destUnit === 'relative' ? 0 : this.durationObject.findIndex((eachUnit) => eachUnit.unit === destUnit);
    let rVal = value;
    let rUnit = '';
    if (srcIndex === destIndex) {
      rUnit = this.durationObject[destIndex].name;
    }
    // convert smaller unit to bigger unit. for e.g. days to years
    let divFactor = 1;
    for (let i = srcIndex - 1; i >= destIndex; i--) {
      divFactor *= this.durationObject[i].factor;
    }
    for (const obj of this.durationObject) {
      if (value >= divFactor) {
        rVal = Math.round(value / divFactor);

        if (shortName) {
          rUnit = obj.unit;
        } else {
          rUnit = obj.name;
        }
        break;
      } else {
        divFactor /= obj.factor;
      }
    }
    if (shortName) {
      return rVal + rUnit;
    }
    if (rVal === 1) {
      if (enableSinglUnitDisplay) {
        return this.singleUnitDisplayName[rUnit];
      } else {
        return rVal + ' ' + rUnit;
      }
    }
    return rVal + ' ' + rUnit + 's';
  }
}
