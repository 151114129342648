import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {
  transform(value: number, precision: number = 1): any {
    // Return the value if it is NAN, null or < 1000
    if (isNaN(value) || value === 0 || value === null || value < 1000) {
      return value;
    }

    // Get absolute value
    let abs = Math.abs(value);
    const rounder = Math.pow(10, 1);
    let key = '';

    const powers = [
      { unit: 'Q', value: Math.pow(10, 15) },
      { unit: 'T', value: Math.pow(10, 12) },
      { unit: 'B', value: Math.pow(10, 9) },
      { unit: 'M', value: Math.pow(10, 6) },
      { unit: 'K', value: 1000 }
    ];

    for (const [index] of powers.entries()) {
      let reduced = abs / powers[index].value;

      // For More Accuracy, get the floor using rounder
      reduced = Math.floor(reduced * rounder) / rounder;

      if (reduced >= 1) {
        abs = reduced;
        key = powers[index].unit;
        break;
      }
    }
    return abs.toFixed(precision) + ' ' + key;
  }
}
