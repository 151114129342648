import * as i0 from "@angular/core";
export class TranslationsService {
    constructor() {
        this.translations = {};
        this.set = (key, value) => {
            this.translations[key] = value;
        };
        this.format = (str, ...args) => str.replace(/\$(\d+)/g, (match, index) => (args && args[index]) || match);
        this.log = () => console.log(this.translations);
    }
    get(key, ...args) {
        let val = this.translations.hasOwnProperty(key) ? this.translations[key] : key;
        val = this.format(val, ...args);
        val = val.replace(/&amp;/g, '&');
        return val;
    }
}
TranslationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TranslationsService_Factory() { return new TranslationsService(); }, token: TranslationsService, providedIn: "root" });
