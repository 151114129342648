import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit() {}

  logout(e: Event) {
    e.preventDefault();
    this.authService.logout();
  }
}
