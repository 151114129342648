import { Input } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'text-alert',
  templateUrl: './text-alert.component.html',
  styleUrls: ['./text-alert.component.scss']
})
export class TextAlertComponent {
  // Acceptable inputs ['info', 'warning', 'error', 'success']
  @Input() alertType: 'info' | 'warning' | 'error' | 'success' = 'info'; // setting info type as default it is optional to send

  constructor() {}
}
