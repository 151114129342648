import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { LoggerConfig, NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { User } from '../core/datamodel/user';

@Injectable()
export class LoggingService {
  appInsights: ApplicationInsights;

  constructor(private logger: NGXLogger) {}

  logPageView(name?: string, url?: string) {
    if (this.appInsights && this.appInsights.appInsights.isInitialized()) {
      this.appInsights.trackPageView({
        name,
        uri: url
      });
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (this.appInsights && this.appInsights.appInsights.isInitialized()) {
      this.appInsights.trackEvent({ name }, properties);
    }
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (this.appInsights && this.appInsights.appInsights.isInitialized()) {
      this.appInsights.trackMetric({ name, average }, properties);
    }
  }

  logException(name: string, message: any, severityLevel?: number) {
    this.logger.error(name, message);
    if (this.appInsights && this.appInsights.appInsights.isInitialized()) {
      this.appInsights.trackException({
        exception: {
          name,
          message
        },
        severityLevel
      });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.logger.trace(message, properties);
    if (this.appInsights && this.appInsights.appInsights.isInitialized()) {
      this.appInsights.trackTrace({ message }, properties);
    }
  }

  logDebug(message: string, properties?: { [key: string]: any }) {
    this.logger.debug(message, properties || '');
    if (this.appInsights && this.appInsights.appInsights.isInitialized()) {
      this.appInsights.trackTrace({ message }, properties);
    }
  }

  logWarning(message: string, properties?: { [key: string]: any }) {
    this.logger.warn(message);
    if (this.appInsights && this.appInsights.appInsights.isInitialized()) {
      this.appInsights.trackTrace({ message }, properties);
    }
  }

  logInfo(message: string, properties?: { [key: string]: any }) {
    this.logger.info(message, properties || '');
    if (this.appInsights && this.appInsights.appInsights.isInitialized()) {
      this.appInsights.trackTrace({ message }, properties);
    }
  }

  setUser(user: User) {
    if (this.appInsights && this.appInsights.appInsights.isInitialized()) {
      this.appInsights.setAuthenticatedUserContext(user.email, user.companyName);
      this.logDebug(`Authenticated user set [${user.email}]. Company Name set [${user.companyName}]`);
    }
  }

  clearUser() {
    if (this.appInsights && this.appInsights.appInsights.isInitialized()) {
      this.appInsights.clearAuthenticatedUserContext();
      this.logDebug('Authenticated user cleared');
    }
  }

  update(loggingLevel: string, loggingInstrumentKey: string) {
    this.updateLogConfig(loggingLevel);
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: loggingInstrumentKey,
        enableAutoRouteTracking: true
      }
    });
    this.logger.debug(`Initialized application insights [${this.appInsights.config.instrumentationKey}]`);
    this.appInsights.loadAppInsights();
  }

  updateLogConfig(loggingLevel: string) {
    if (loggingLevel) {
      const loggerConfig = new LoggerConfig();

      if (loggingLevel) {
        if (loggingLevel === 'Trace') {
          loggerConfig.level = NgxLoggerLevel.TRACE;
        } else if (loggingLevel === 'Debug') {
          loggerConfig.level = NgxLoggerLevel.DEBUG;
        } else if (loggingLevel === 'Info') {
          loggerConfig.level = NgxLoggerLevel.INFO;
        } else if (loggingLevel === 'Warn') {
          loggerConfig.level = NgxLoggerLevel.WARN;
        } else if (loggingLevel === 'Error') {
          loggerConfig.level = NgxLoggerLevel.ERROR;
        }
      }
      this.logger.updateConfig(loggerConfig);
    }
  }
}
