import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User, UserWrapper, UserRole } from '../datamodel/user';
import { get } from 'lodash';
import { Router } from '@angular/router';
import { LoggingService } from '../../logging/logging.service';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { ConstantService } from '../constant.service';
import { Base64 } from 'js-base64';
import { OfferingType, TrialLimitOfferingType } from '../datamodel/offering';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
import * as i3 from "@angular/router";
import * as i4 from "../../logging/logging.service";
import * as i5 from "../constant.service";
export class AuthService {
    constructor(http, cookieService, router, loggingService, constants) {
        this.http = http;
        this.cookieService = cookieService;
        this.router = router;
        this.loggingService = loggingService;
        this.constants = constants;
        this.currentUserSubject = new BehaviorSubject(this.currentUserWrapper);
        this.LOGGED_IN_USER_KEY = 'loggedInUser';
        this.REDIRECT_TO_KEY = 'redirectToPostLogin';
        this.REMEMBERED_USERS_KEY = '_users';
        if (!environment.multiCommcellDeployment && this.cookieService.check(this.LOGGED_IN_USER_KEY)) {
            this.currentUserWrapper = new UserWrapper();
            this.currentUserWrapper.user = new User();
            this.currentUserWrapper.user.fromJSON(JSON.parse(this.cookieService.get(this.LOGGED_IN_USER_KEY)));
            this.currentUserSubject.next(this.currentUserWrapper);
        }
    }
    get currentUserValue() {
        return this.currentUserWrapper && this.currentUserWrapper.user;
    }
    get authCode() {
        return this.currentUserWrapper && this.currentUserWrapper.authcode;
    }
    get companyId() {
        return this.currentUserWrapper && this.currentUserWrapper.user.companyId;
    }
    overwriteCompanyDetails(detail) {
        // remember old values
        this.currentUserWrapper.user.initialCompanyName = this.currentUserWrapper.user.companyName;
        this.currentUserWrapper.user.initialCompanyId = this.currentUserWrapper.user.companyId;
        // overwrite with new values
        this.currentUserWrapper.user.companyId = detail.companyId.toString();
        this.currentUserWrapper.user.companyName = detail.companyName;
    }
    setUserRole(role) {
        this.currentUserWrapper.role = role;
    }
    setNavSettings(includedNavItems) {
        this.currentUserWrapper.includedNavItems = includedNavItems;
    }
    isNavItemIncluded(solutionType) {
        return this.currentUserWrapper && this.currentUserWrapper.includedNavItems[solutionType];
    }
    getUrlPrefix() {
        const loc = window.location;
        let urlPrefix = `${loc.origin}${loc.pathname}`;
        if (!urlPrefix.endsWith('/')) {
            urlPrefix += '/';
        }
        return urlPrefix;
    }
    getLoginUrl(companyId) {
        return environment.loginUrl + (companyId ? '?companyId=' + companyId : '');
    }
    rememberUser(user) {
        let users = {};
        if (this.cookieService.check(this.REMEMBERED_USERS_KEY)) {
            users = JSON.parse(Base64.decode(this.cookieService.get(this.REMEMBERED_USERS_KEY)));
        }
        users[user.email || user.userName] = {
            name: user.fullName,
            url: window.location.origin
        };
        this.cookieService.set(this.REMEMBERED_USERS_KEY, Base64.encode(JSON.stringify(users)), 365, '/', this.getDomain(), true);
    }
    removeRememberedUser(email) {
        if (this.cookieService.check(this.REMEMBERED_USERS_KEY)) {
            const usersMap = JSON.parse(Base64.decode(this.cookieService.get(this.REMEMBERED_USERS_KEY)));
            const users = {};
            for (const key in usersMap) {
                if (usersMap.hasOwnProperty(key) && key !== email) {
                    users[key] = usersMap[key];
                }
            }
            this.cookieService.set(this.REMEMBERED_USERS_KEY, Base64.encode(JSON.stringify(users)), 365, '/', this.getDomain(), true);
        }
    }
    getDomain() {
        const hostName = window.location.hostname;
        const domainMatch = hostName.match(/.*\.(.*\..*)/);
        let domain = hostName;
        if (domainMatch && domainMatch.length > 1) {
            domain = domainMatch[1];
        }
        return domain;
    }
    // resp format: "QSDKForSSO<space><Base64 encoded value of CheckCredentialResponse(Login API response)"
    processLoginResp(token) {
        if (token) {
            try {
                return this.http.post(`${this.getAuthUrl()}${this.constants.apis.access}`, { idtoken: token }).pipe(map((resp) => {
                    let isLoginSuccess = false;
                    if (resp && resp.error_code === 0) {
                        let loginResp = resp.data.accesskey;
                        loginResp = Base64.decode(loginResp);
                        const respObj = JSON.parse(loginResp);
                        let user = null;
                        if (respObj &&
                            respObj.token &&
                            respObj.providerOrganization &&
                            respObj.providerOrganization.providerDomainName) {
                            let orgInfo = respObj.providerOrganization;
                            if (respObj.providerType !== 'Organization' &&
                                respObj.ownerOrganization &&
                                respObj.ownerOrganization.providerId > 0) {
                                orgInfo = respObj.ownerOrganization;
                            }
                            const companyName = orgInfo.providerDomainName;
                            const companyId = orgInfo.providerId;
                            user = new User(respObj.userName, respObj.smtpAddress, respObj.userGUID, companyName, companyId, respObj.token, respObj.aliasName // alias name is userId
                            );
                            this.currentUserWrapper = new UserWrapper();
                            this.currentUserWrapper.user = user;
                            this.cookieService.set(this.LOGGED_IN_USER_KEY, JSON.stringify(user), undefined, undefined, undefined, true);
                            this.currentUserSubject.next(this.currentUserWrapper);
                            isLoginSuccess = true;
                        }
                    }
                    return isLoginSuccess;
                }));
            }
            catch (e) {
                console.error(e);
            }
        }
        return of(false);
    }
    getCompanyDetails() {
        return this.http.get(`${environment.productFnApiBaseUrl}${this.constants.apis.company}/${this.currentUserWrapper.user.companyId}`);
    }
    updateMetadata() {
        setTimeout(() => {
            if (this.currentUserWrapper.role !== UserRole.MSP_ADMIN && this.currentUserWrapper.role !== UserRole.MSP_USER) {
                this.getCompanyDetails().subscribe((compResp) => {
                    if (compResp && compResp.error_code === 0 && compResp.data) {
                        const companyDetails = compResp.data;
                        this.currentUserWrapper.user.companyGuid = companyDetails.guid;
                        this.currentUserWrapper.authcode = companyDetails.authcode;
                        this.currentUserWrapper.user.setName(companyDetails.fullName);
                        this.currentUserWrapper.completedSolutions = companyDetails.completedSolutions
                            ? JSON.parse(companyDetails.completedSolutions)
                            : {};
                        this.currentUserWrapper.isNewUser = this.isNewUser(this.currentUserWrapper.completedSolutions);
                        this.currentUserWrapper.country = get(companyDetails, 'metallicHomeRegion.zones.zone[0].country', companyDetails.country);
                        this.currentUserWrapper.srcRegionId = companyDetails.regionId;
                        this.currentUserWrapper.isMultiRegionEnabled = companyDetails.multiRegion;
                        this.currentUserWrapper.isMSPTenant = companyDetails.isMSPTenant;
                        this.currentUserWrapper.trialLimitStatus = companyDetails.trialLimitStatus || {};
                        if (this.currentUserWrapper.trialLimitStatus[TrialLimitOfferingType.HYBRID] ||
                            this.currentUserWrapper.trialLimitStatus[TrialLimitOfferingType.MRR_AZURE_COOL] ||
                            this.currentUserWrapper.trialLimitStatus[TrialLimitOfferingType.MRR_AZURE_HOT] ||
                            this.currentUserWrapper.trialLimitStatus[TrialLimitOfferingType.MRR_OCI_INFREQUENT] ||
                            this.currentUserWrapper.trialLimitStatus[TrialLimitOfferingType.MRR_OCI_STANDARD]) {
                            this.currentUserWrapper.trialLimitStatus[OfferingType.COMPUTE] = true;
                            this.currentUserWrapper.trialLimitStatus[OfferingType.DATABASE] = true;
                            this.currentUserWrapper.trialLimitStatus[OfferingType.FILE_OBJECT] = true;
                        }
                        this.currentUserWrapper.isSharedInfraConfigured = companyDetails.isSharedInfraConfigured || false;
                        this.rememberUser(this.currentUserWrapper.user);
                        this.loggingService.setUser(this.currentUserWrapper.user);
                        this.resolveCurrentUserWrapperMetaData();
                    }
                    else {
                        this.loggingService.logException('Error while getting company details', compResp.error_message, SeverityLevel.Critical);
                        this.router.navigate(['error']);
                    }
                }, (error) => {
                    this.loggingService.logException('Login exception', error, SeverityLevel.Critical);
                    if (error.status && error.status !== 401 && error.status !== 403) {
                        this.router.navigate(['error']);
                    }
                });
            }
        });
    }
    resolveCurrentUserWrapperMetaData() {
        this.currentUserWrapper.isMetaDataResolved = true;
        this.currentUserSubject.next(this.currentUserWrapper);
    }
    isNewUser(completedSolutions) {
        return (!completedSolutions ||
            Object.keys(completedSolutions).length === 0 ||
            (Object.keys(completedSolutions).length === 1 && completedSolutions.hasOwnProperty('CORE_SETUP')));
    }
    clearLoggedInUserInfo() {
        this.loggingService.clearUser();
        this.currentUserSubject.next(undefined);
        this.cookieService.delete(this.LOGGED_IN_USER_KEY);
    }
    logout() {
        this.clearLoggedInUserInfo();
        window.location.href = environment.logoutUrl;
    }
    relogin() {
        this.clearLoggedInUserInfo();
        window.location.href = environment.loginUrl;
    }
    getAuthUrl() {
        // tslint:disable-next-line: no-string-literal
        const settings = window['settings'];
        return (settings && settings.authUrl) || environment.authUrl;
    }
    isLaptopOwner() {
        return this.http.get(`${environment.productFnApiBaseUrl}${this.constants.apis.laptopOwner}`).pipe(map((resp) => {
            return resp === true;
        }), catchError((error) => {
            this.loggingService.logException('Failed to determine laptop owner', error);
            return of(false);
        }));
    }
    isO365SelfServiceEnabled() {
        return this.http.get(`${environment.productFnApiBaseUrl}${this.constants.apis.o365SelfService}`).pipe(map((resp) => {
            return resp && resp.selfServiceEnabled && !resp.showAdminView;
        }), catchError((error) => {
            this.loggingService.logException('Failed to determine whether self service is enabled or not', error);
            return of(false);
        }));
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.LoggingService), i0.ɵɵinject(i5.ConstantService)); }, token: AuthService, providedIn: "root" });
