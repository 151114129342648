import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { CoreUtil } from '../core.util';

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
  loginResp = '';
  redirectUrl = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private cookieService: CookieService,
    private coreUtil: CoreUtil
  ) {}

  ngOnInit() {
    this.loginResp = this.route.snapshot.queryParams.token;
    const companyId = this.route.snapshot.queryParams.companyId;
    // get redirect url from cookie or default to '/'
    this.redirectUrl = this.cookieService.get(this.authService.REDIRECT_TO_KEY) || '/';
    this.cookieService.delete(this.authService.REDIRECT_TO_KEY);
    if (this.loginResp) {
      this.authService.processLoginResp(this.loginResp).subscribe((flag) => {
        if (flag) {
          if (
            environment.redirectDashboardToProduct &&
            (this.redirectUrl === '/dashboard' || this.redirectUrl === '/')
          ) {
            window.location.href = environment.productUrl;
          } else {
            let queryParams: any = {};
            let urlToRedirect = this.redirectUrl;
            if (this.redirectUrl.indexOf('?') > 0) {
              queryParams = this.coreUtil.getQueryParamsFromUrl(this.redirectUrl);
              // if companyId is found in the /login route but not in remembered redirectUrl, then use it
              if (companyId && !queryParams.companyId) {
                queryParams.companyId = companyId;
              }
              urlToRedirect = this.redirectUrl.split('?')[0];
            } else {
              if (companyId) {
                queryParams.companyId = companyId;
              }
            }
            this.router.navigate([urlToRedirect], {
              queryParams
            });
          }
        } else {
          console.error('something wrong while processing login response');
          this.authService.logout();
          // this.router.navigate(['/error']);
        }
      });
    } else {
      // this should not happen ideally
      console.error('login response from product is empty');
      this.router.navigate(['/error']);
    }
  }
}
