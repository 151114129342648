<mdb-navbar SideClass="navbar navbar-expand-lg" [containerInside]="false">

  <mdb-navbar-brand>
    <a class="navbar-brand" [routerLink]="isReferrerExternal ? '/ac-redirect' : '/'"
      [state]="isReferrerExternal ? {url: homeUrl} : {}"><img [src]="logoSrc" alt="Logo" width="180" max-height="40"></a>
  </mdb-navbar-brand>

  <links>
    <ul class="navbar-nav ml-auto nav-flex-icons">
      <!-- <ngx-avatar class="user-avatar" size="35" bgColor="{{avatarBgColor}}" name="{{user?.fullName}}"
        [mdbPopover]="popTemplate" triggers="focus">
      </ngx-avatar> -->
      <li *ngIf="user && user.initialCompanyId && user.initialCompanyId !== user.companyId" class="nav-item">
        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Company: {{user.companyName}}</a>
      </li>
      <li *ngIf="user" class="nav-item dropdown" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          <mdb-icon fas icon="user"></mdb-icon>{{user.userName}}<span class="caret"></span>
        </a>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown" role="menu">
          <div class="d-flex flex-column p-3">
            <div class="d-flex flex-row">
              <div class="mr-3">
                <ngx-avatar size="70" bgColor="{{avatarBgColor}}" name="{{user.fullName}}"></ngx-avatar>
              </div>
              <div class="d-flex flex-column mt-1">
                <div class="user-name">{{user.fullName}}</div>
                <div class="user-company">{{(user.initialCompanyId && user.initialCompanyId !== user.companyId) ? user.initialCompanyName : user.companyName }}</div>
                <div class="user-email">{{user.email}}</div>
                <a class="mt-2 text-right logout-link" mdbWavesEffect href="#" (click)="logout($event)">Log out</a>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </links>
</mdb-navbar>
