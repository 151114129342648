import { OfferingType, FeatureType } from './datamodel/offering';
import * as i0 from "@angular/core";
export class ConstantService {
    constructor() {
        this.COMPANY_NAME_ALLOWED_LENGTH = 40;
        this.MS_AD_STORAGE_TYPE = 8;
        // String to append with Arn(Amazon resource name) value
        this.AWS_STS_ROLE_IAM_SUFFIX = '|-|IAM';
        this.urlPathParams = {
            TYPE: 'type',
            CATEGORY: 'category'
        };
        this.urlQueryParams = {
            VENDOR: 'vendor',
            ACTION: 'action'
        };
        this.urlQueryParamsValues = {
            CREATE: 'create'
        };
        this.deploymentType = {
            ONPREM: 'on-prem',
            CLOUD: 'cloud'
        };
        this.appType = {
            FS: 'fileserver',
            SQL: 'sql',
            SAP_HANA: 'sapHana',
            ORACLE: 'oracle',
            MICROSOFT_AD: 'microsoftAD',
            VM: 'vsa',
            CLOUD_APPS: 'cloudApps',
            KUBERNETES: 'kubernetes',
            SALESFORCE: 'salesforce',
            AWS_DB: 'aws-db',
            AWS_EC2: 'aws-ec2',
            OCI_VM: 'oci-vm'
        };
        this.osType = {
            WINDOWS: 'win',
            UNIX: 'unix',
            MAC: 'mac',
            WIN32: 'win32',
            WIN64: 'win64',
            HPUNIX: 'hpux',
            AIX: 'aix'
        };
        this.solutionType = {
            ADMIN_CONSOLE: 'ADMIN_CONSOLE',
            VSA: 'VSA',
            DBS: 'DBS',
            ACTIVE_DIRECTORY: 'ACTIVE_DIRECTORY',
            OBJECT_STORAGE: 'OBJECT_STORAGE',
            OFFICE365: 'OFFICE365',
            ENDPOINT: 'ENDPOINT',
            SALESFORCE: 'SALESFORCE',
            DYNAMICS365: 'DYNAMICS365',
            ARCHIVING: 'ARCHIVING'
        };
        // Solution type nav item map for quicker lookup
        this.navKeysSolutionTypeMap = {
            ['fsServersList']: 'ADMIN_CONSOLE',
            ['instances']: 'DBS',
            ['dbDatabases']: 'DBS',
            // ['dbInstancesClone']: 'DBS',
            ['hypervisors']: 'VSA',
            ['vmGroups']: 'VSA',
            ['userVMs']: 'VSA',
            ['clusters']: 'VSA',
            ['applicationGroups']: 'VSA',
            ['applications']: 'VSA',
            ['office365V2']: 'OFFICE365',
            ['devices']: 'ENDPOINT',
            ['cloudStorageApp']: 'OBJECT_STORAGE',
            ['salesforceOrganizations']: 'SALESFORCE',
            ['dynamics365']: 'DYNAMICS365',
            ['activeDirectory']: 'ACTIVE_DIRECTORY',
            ['archiveFileServersV2']: 'ARCHIVING'
        };
        this.featureTypeSolutiontypeMap = {
            [OfferingType.COMPUTE]: 'VSA',
            [FeatureType.VMWARE]: 'VSA',
            [FeatureType.AZUREVM]: 'VSA',
            [FeatureType.KUBERNETES]: 'VSA',
            [FeatureType.HYPERV]: 'VSA',
            [FeatureType.AWS_EC2]: 'VSA',
            [FeatureType.OCI_VM]: 'VSA',
            [FeatureType.NUTANIX_AHV]: 'VSA',
            [OfferingType.DATABASE]: 'DBS',
            [FeatureType.AWS_DB]: 'DBS',
            [FeatureType.ORACLE]: 'DBS',
            [FeatureType.ORACLE_RAC]: 'DBS',
            [FeatureType.Azure_DB]: 'DBS',
            [FeatureType.SQL_SERVER]: 'DBS',
            [FeatureType.SAP_HANA]: 'DBS',
            [OfferingType.ACTIVE_DIRECTORY]: 'ACTIVE_DIRECTORY',
            [FeatureType.AZURE_AD]: 'ACTIVE_DIRECTORY',
            [FeatureType.MICROSOFT_AD]: 'ACTIVE_DIRECTORY',
            [FeatureType.OBJECT_STORAGE]: 'OBJECT_STORAGE',
            [FeatureType.AWS_S3]: 'OBJECT_STORAGE',
            [FeatureType.OCI_OBJECT_STORAGE]: 'OBJECT_STORAGE',
            [FeatureType.FILE_SYSTEM]: 'ADMIN_CONSOLE',
            [OfferingType.O365]: 'OFFICE365',
            [FeatureType.O365_BYOS]: 'OFFICE365',
            [FeatureType.ENDPOINT]: 'ENDPOINT',
            [FeatureType.SALESFORCE]: 'SALESFORCE',
            [FeatureType.D365]: 'DYNAMICS365'
        };
        this.vmVendorType = {
            VMWARE: 'vmware',
            HYPERV: 'hyperv',
            AZURE: 'azurevm'
        };
        this.objStorageVendorType = {
            AZ_BLOB: 'az-blob',
            AZ_FILE: 'az-file',
            AWS_S3: 'aws-s3',
            OCI_OBJECT_STORAGE: 'oci-os'
        };
        this.storageAccountType = {
            METALLIC: 'metallic',
            BYOS: 'byos'
        };
        this.mcsStorageTiers = {
            MCS_AZ_HOT: 'mcs-az-hot',
            MCS_AZ_COOL: 'mcs-az-cool',
            MCS_OCI_STANDARD: 'mcs-oci-standard',
            MCS_OCI_INFREQUENT: 'mcs-oci-infrequent'
        };
        this.mcsOffering = {
            HOT_GRS: 1,
            COOL_LRS: 6,
            OCI_STANDARD: 11,
            OCI_INFREQUENT: 12
        };
        this.storageVendorType = {
            AZURE: 'azure',
            AWS: 'aws',
            OCI: 'oci'
        };
        this.serverOsType = {
            win: 16,
            unix: 3
        };
        this.analytics = {
            pendo: 'pendo',
            gainsight: 'gainsight'
        };
        this.gatewayOsType = {
            WINDOWS: 210,
            LINUX: 98
        };
        this.osTypes = ['Windows', 'Linux'];
        this.osTypesMap = {
            WINDOWS: 'Windows',
            LINUX: 'Linux'
        };
        this.awsAuthMethods = {
            IAM: 'iamRole',
            ACCESS_KEYS: 'accessKeys',
            STS: 'assumeRole'
        };
        this.awsAuthMethodsValuesMap = {
            [this.awsAuthMethods.IAM]: 1,
            [this.awsAuthMethods.ACCESS_KEYS]: 2,
            [this.awsAuthMethods.STS]: 3
        };
        this.awsAuthMethodsCCValuesMap = {
            [this.awsAuthMethods.IAM]: 'IAMRole',
            [this.awsAuthMethods.ACCESS_KEYS]: 'AccessAndSecretKey',
            [this.awsAuthMethods.STS]: 'STSRole'
        };
        this.regions = ['US', 'Canada', 'France', 'Germany', 'Japan', 'India', 'China', 'Australia'];
        this.featureServerType = [
            OfferingType.O365,
            OfferingType.ENDPOINT,
            OfferingType.COMPUTE,
            FeatureType.OBJECT_STORAGE,
            FeatureType.AZUREVM,
            FeatureType.KUBERNETES,
            FeatureType.SALESFORCE,
            FeatureType.SALESFORCE_AZURE,
            FeatureType.O365_BYOS,
            FeatureType.AZURE_MAAS,
            FeatureType.AWS_WORKLOAD,
            FeatureType.OCI_WORKLOAD
        ];
        this.apis = {
            /* Auth APIs */
            access: 'access',
            /* core APIs */
            company: 'Company',
            userMappings: 'getusermappings',
            laptopOwner: 'device/isOwner',
            organization: 'Organization',
            o365SelfService: 'Office365/SelfService',
            /* dashboard APIs */
            coreDataSources: 'statistics/protected-entities/core',
            endpointDataSources: 'statistics/protected-entities/endpoint',
            o365DataSources: 'statistics/protected-entities/office365',
            o365Plans: 'statistics/plans/office365',
            d365Plans: 'dynamics365/plan/application',
            endpointPlans: 'statistics/plans/endpoint',
            jobStats: 'statistics/jobs',
            coreSla: 'statistics/sla/core',
            o365Sla: 'statistics/sla/office365',
            storageStats: 'statistics/storage-target',
            storageStatsV2: 'v2/statistics/storage-target',
            d365Configuration: 'dynamics365/configuration',
            archiveContentGroup: 'archive/ContentGroup',
            /* backup config or provisioning APIs */
            agent: 'agent',
            backup: 'backup',
            client: 'Client',
            clientBeacon: 'client-becon',
            cloudStorage: 'cloudstorage',
            companyMediaAgents: 'company-mediaagents',
            content: 'content',
            clone: 'msp-endpoint-plan/clone',
            gateway: 'gateway',
            gatewaysV2: 'gateways',
            hypervisor: 'hypervisor',
            hypervisorSubclients: 'hypervisor-subclients',
            v4Hypervisor: 'v4/Hypervisor',
            install: 'install',
            agentInstall: 'package/install',
            job: 'job',
            jobs: 'jobs',
            msAdStorage: 'microsoftad/storage',
            mspEndpointPlan: 'msp-endpoint-plan',
            mspEndpointPlanV2: 'v2-msp-endpoint-plan',
            mspExchangePlan: 'msp-exchange-plan',
            mspO365Plan: 'msp-o365-plan',
            mspD365Plan: 'dynamics365/plan/server',
            mspAzureAdPlan: 'azuread/plan/server',
            createSalesforcePlan: 'salesforce/plan/azure',
            getSalesforcePlan: 'salesforce/plan',
            objectstorage: 'objectstorage',
            objectstorageV2: 'v2/objectstorage',
            onpremStorage: 'onprem-storage',
            oracleInstances: '',
            package: 'package',
            plan: 'plan',
            planOracle: 'plan/oracle',
            createSalesforceAWSPlan: 'salesforce/plan/amazon',
            createMicrosoftAdPlan: 'plan/microsoftad',
            remoteCache: 'remotecache',
            subclient: 'subclient',
            supportedRegions: 'supported-regions',
            vendorStorage: 'vendorstorage',
            vmBrowse: 'vmbrowse',
            awsContentBrowse: 'amazons3-browsecontent',
            awsAddContent: 'amazons3-defaultcontentgroup',
            complianceGroups: 'compliance-groups',
            o365ServerPlan: 'o365/plan/server',
            awsAuthMethodsUrls: 'aws-createstack-url',
            statuspageApi: 'status-page-url',
            ociIamUrl: 'oci-createstack-url',
            credential: 'CommCell/Credentials',
            /* Business APIs */
            subscription: 'subscription',
            subscriptionV2: 'v2/subscription',
            buyNow: 'buy-now',
            /* misc APIs */
            cacheUpdate: 'cacheupdate',
            ringInfo: 'ring-info',
            regionStatus: 'region-status',
            preferences: 'hub/user-preferences',
            accessNode: 'kubernetes-access-nodes',
            tenantSettings: 'tenant-settings'
        };
        this.ccAPIs = {
            migrationSummary: 'MigrationGroups/Summary'
        };
        this.awsAuthMethodAppTypePoliciesMap = {
            [this.awsAuthMethods.IAM]: {
                [this.appType.AWS_DB]: [
                    'MetallicRole-RDSPolicy',
                    'MetallicRole-DynamoDBPolicy',
                    'MetallicRole-DocDBPolicy',
                    'MetallicRole-RedshiftPolicy'
                ],
                [this.appType.AWS_EC2]: ['MetallicRole-EC2Policy', 'MetallicRole-VMConversionPolicy'],
                // cloud apps is for aws s3
                [this.appType.CLOUD_APPS]: ['MetallicRole-S3Policy']
            },
            [this.awsAuthMethods.ACCESS_KEYS]: {
                [this.appType.AWS_DB]: [
                    'MetallicGroup-RDSPolicy',
                    'MetallicGroup-DynamoDBPolicy',
                    'MetallicGroup-DocDBPolicy',
                    'MetallicGroup-RedshiftPolicy',
                    'MetallicGroup-S3Policy'
                ],
                [this.appType.AWS_EC2]: ['MetallicGroup-EC2Policy', 'MetallicGroup-VMConversionPolicy', 'MetallicGroup-S3Policy'],
                // cloud apps is for aws s3
                [this.appType.CLOUD_APPS]: ['MetallicGroup-S3Policy']
            },
            [this.awsAuthMethods.STS]: {
                [this.appType.AWS_DB]: [
                    'MetallicAdminRole-RDSPolicy',
                    'MetallicAdminRole-DynamoDBPolicy',
                    'MetallicAdminRole-DocDBPolicy',
                    'MetallicAdminRole-RedshiftPolicy',
                    'MetallicAdminRole-S3Policy',
                    'MetallicAdminRole-STSAssumePolicy'
                ],
                [this.appType.AWS_EC2]: [
                    'MetallicAdminRole-EC2Policy',
                    'MetallicAdminRole-VMConversionPolicy',
                    'MetallicAdminRole-S3Policy',
                    'MetallicAdminRole-STSAssumePolicy'
                ],
                // cloud apps is for aws s3
                [this.appType.CLOUD_APPS]: ['MetallicAdminRole-S3Policy', 'MetallicAdminRole-STSAssumePolicy']
            }
        };
        this.authMethodConfigRoleMap = {
            [this.awsAuthMethods.IAM]: 'MetallicRole',
            [this.awsAuthMethods.ACCESS_KEYS]: 'MetallicUserGroup',
            [this.awsAuthMethods.STS]: 'MetallicAdminRole'
        };
        this.authMethodConfigRoleTypeMap = {
            [this.awsAuthMethods.IAM]: 'IAM Role',
            [this.awsAuthMethods.ACCESS_KEYS]: 'IAM User Group',
            [this.awsAuthMethods.STS]: 'IAM Role'
        };
        this.awsBucketNamePattern = '[a-z0-9-.]+';
        this.gatewayWorkLoadValues = {
            AWS_GATEWAY_WORKLOAD: '1',
            OCI_GATEWAY_WORKLOAD: '2'
        };
        this.storageNameMaxLength = 60;
    }
}
ConstantService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConstantService_Factory() { return new ConstantService(); }, token: ConstantService, providedIn: "root" });
