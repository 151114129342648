import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, Observer, concat } from 'rxjs';
import { ApiStates } from '../core.enum';
import { TranslationsService } from 'src/app/translations/translations.service';
import { LoggingService } from 'src/app/logging/logging.service';

@Component({
  selector: 'steps-executor',
  templateUrl: './steps-executor.component.html',
  styleUrls: ['./steps-executor.component.scss']
})
export class StepsExecutorComponent implements OnInit, OnChanges {
  @Input() isAlreadyConfigured: boolean;
  @Input() steps: Array<StepsExecutorConfig> = [];
  @Output() stepsExecuted = new EventEmitter<any>();

  isExecutionInProgress = false;

  constructor(private translationService: TranslationsService, private loggingService: LoggingService) {}

  ngOnInit() {
    this.initLabels();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.steps && changes.steps.currentValue && changes.steps.currentValue !== changes.steps.previousValue) {
      this.initLabels();
    }
  }

  initLabels() {
    if (this.steps) {
      this.steps.forEach(step => {
        step.labels = {};
        for (const state in ApiStates) {
          if (ApiStates.hasOwnProperty(state)) {
            const sVal = ApiStates[state];
            step.labels[sVal] = this.translationService.get(`${step.i18nKeyPrefix}${sVal}`, ...step.argsToFormat);
          }
        }
      });
    }
  }
  executeStep(step: StepsExecutorConfig, observer: Observer<boolean>) {
    step.execStep$.subscribe(
      isExecuteSuccess => {
        if (isExecuteSuccess) {
          step.status = 'completed';
          observer.next(isExecuteSuccess);
          observer.complete();
        } else {
          step.status = 'failed';
          observer.error(step.labels[step.status]);
        }
      },
      error => {
        observer.error(error);
      }
    );
  }

  executeSteps() {
    const stepsToBeExecuted: Observable<boolean>[] = [];
    this.steps.forEach(step => {
      step.status = 'active';
      if (step.execStep$) {
        stepsToBeExecuted.push(
          new Observable((observer: Observer<boolean>) => {
            if (this.isAlreadyConfigured && step.isAlreadyDone$) {
              step.isAlreadyDone$.subscribe(
                isAlreadyDoneSuccess => {
                  if (isAlreadyDoneSuccess) {
                    step.status = 'completed';
                    observer.next(isAlreadyDoneSuccess);
                    observer.complete();
                  } else {
                    this.executeStep(step, observer);
                  }
                },
                error => {
                  observer.error(error);
                }
              );
            } else {
              this.executeStep(step, observer);
            }
          })
        );
      }
    });
    if (stepsToBeExecuted.length > 0) {
      this.isExecutionInProgress = true;
      concat(...stepsToBeExecuted).subscribe(
        () => {
          // do nothing
        },
        error => {
          this.isExecutionInProgress = false;
          this.loggingService.logException('Failed to execute step with the following error', error);
          this.stepsExecuted.emit({ isSuccess: false, error_message: error });
        },
        () => {
          this.isExecutionInProgress = false;
          this.stepsExecuted.emit({ isSuccess: true });
        }
      );
    }
  }
}

export class StepsExecutorConfig {
  i18nKeyPrefix: string;
  argsToFormat: string[];
  isAlreadyDone$: Observable<boolean>;
  execStep$: Observable<boolean>;
  status: string;
  labels: any;

  constructor(
    i18nKeyPrefix: string,
    execStep$: Observable<boolean>,
    isAlreadyDone$?: Observable<boolean>,
    argsToFormat?: string[]
  ) {
    this.i18nKeyPrefix = i18nKeyPrefix;
    this.isAlreadyDone$ = isAlreadyDone$;
    this.execStep$ = execStep$;
    this.argsToFormat = argsToFormat || [];
  }
}
