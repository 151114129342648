import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from './../environments/environment';
import { AuthService } from './core/auth/auth.service';
import { User, UserWrapper } from './core/datamodel/user';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  user: User;
  appTitle = 'Untitled';
  isIframe: boolean;
  isAppInitialized = false;

  constructor(private titleSvc: Title, private authService: AuthService, private appService: AppService) {}

  ngOnInit() {
    this.appService.isInitializedSubject.subscribe((flag) => {
      this.isAppInitialized = flag;
    });

    this.titleSvc.setTitle(environment.appTitle);
    this.authService.currentUserSubject.subscribe((userWrapper: UserWrapper) => {
      if (userWrapper && userWrapper.user) {
        this.user = userWrapper.user;
      }
    });
  }
}
