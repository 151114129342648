import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from '../logging/logging.service';
import { SeverityLevel } from '@microsoft/applicationinsights-common';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private loggingService: LoggingService) {}
  handleError(error: any) {
    const message = error.message ? error.message : error.toString();

    this.loggingService.logException(error, message, SeverityLevel.Critical);

    throw error;
  }
}
