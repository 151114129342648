import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ButtonsModule,
  CheckboxModule,
  DropdownModule,
  IconsModule,
  InputsModule,
  InputUtilitiesModule,
  MDBSpinningPreloader,
  ModalModule,
  NavbarModule,
  PopoverModule,
  PreloadersModule,
  SelectModule,
  SidenavModule,
  TooltipConfig,
  TooltipModule,
  WavesModule
} from 'ng-uikit-pro-standard';
import { AvatarModule } from 'ngx-avatar';
import { MomentModule } from 'ngx-moment';
import { TranslateDirective } from '../translations/translate.directive';
import { CardMenuOptionComponent } from './card-menu-option/card-menu-option.component';
import { CopyClipboardDirective } from './directives/copy-clipboard.directive';
import { DisableControlDirective } from './directives/disable-control.directive';
import { ErrorPageComponent } from './error-page/error-page.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { DurationPipe } from './pipes/duration.pipe';
import { SizePipe } from './pipes/size.pipe';
import { SidenavComponent } from './sidenav/sidenav.component';
import { StepsExecutorComponent } from './steps-executor/steps-executor.component';
import { RouterModule } from '@angular/router';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { PageLoadingComponent } from './page-loading/page-loading.component';
import { PageMaskComponent } from './page-mask/page-mask.component';
import { OfflineChatComponent } from './chat/offline-chat/offline-chat.component';
import { ContactSupportComponent } from './contact-support/contact-support.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { TextAlertComponent } from './text-alert/text-alert.component';

// split this array into two when not all core components needs to be exported
const componentsArray = [
  LoginComponent,
  ErrorPageComponent,
  HeaderComponent,
  SidenavComponent,
  DurationPipe,
  SizePipe,
  TranslateDirective,
  DisableControlDirective,
  CopyClipboardDirective,
  CardMenuOptionComponent,
  StepsExecutorComponent,
  PageLoadingComponent,
  PageMaskComponent,
  OfflineChatComponent,
  ContactSupportComponent,
  ShortNumberPipe,
  TextAlertComponent
];
const modulesArray = [
  TrimValueAccessorModule,
  AvatarModule,
  FormsModule,
  ReactiveFormsModule,
  InputUtilitiesModule,
  InputsModule,
  CheckboxModule,
  TooltipModule,
  ButtonsModule,
  SelectModule,
  DropdownModule,
  WavesModule,
  IconsModule,
  NavbarModule,
  SidenavModule,
  PreloadersModule,
  ButtonsModule,
  ModalModule,
  MomentModule
];

@NgModule({
  declarations: componentsArray,
  imports: [RouterModule, DropdownModule.forRoot(), PopoverModule.forRoot(), CommonModule, ...modulesArray],
  providers: [MDBSpinningPreloader, TooltipConfig, DurationPipe, SizePipe, ShortNumberPipe],
  exports: [...componentsArray, ...modulesArray]
})
export class CoreModule {}
