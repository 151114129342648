// login response
export class User {
  userName: string;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  userGuid: string;
  companyName: string;
  companyId: string;
  // below properties are resolved after async calls
  token?: string;
  companyGuid?: string;
  // below properties are to remember companyId and Name from login response
  initialCompanyName: string;
  initialCompanyId: string;
  userId: number;

  constructor(
    userName?: string,
    email?: string,
    uGuid?: string,
    cName?: string,
    cId?: string,
    token?: string,
    userId?: string
  ) {
    this.userName = userName;
    this.email = email;
    this.userGuid = uGuid;
    this.companyName = cName;
    this.companyId = cId;
    this.token = token;
    this.userId = Number(userId);
  }
  // as we don't store first name last name in DB, doing below workaround
  setName(fullName: string) {
    this.fullName = fullName;
    const nArr = fullName.split(' ');
    this.firstName = nArr[0];
    if (nArr.length > 1) {
      this.lastName = nArr[1];
    }
  }
  fromJSON(json: any) {
    if (json) {
      Object.assign(this, json);
    }
  }
}
// user + company and profile details
export class UserWrapper {
  user: User;
  isMetaDataResolved = false;
  authcode?: string;
  completedSolutions?: any;
  isNewUser = false;
  srcRegionId: number;
  country: string;
  isMultiRegionEnabled?: any;
  role?: UserRole;
  isMSPTenant = false; // tenant which is created by MSP (reseller mode ON)
  includedNavItems: { [key: string]: boolean } = {}; // stores solutionType(as a key) allowed for navigation
  trialLimitStatus: { [key: string]: boolean } = {};
  isSharedInfraConfigured = false;
}

export enum UserRole {
  UNKNOWN = -1,
  MSP_ADMIN = 0,
  TENANT_ADMIN = 1,
  TENANT_USER = 2,
  MSP_USER = 3,
  RESTRICTED_USER = 4
}

export class UserMappingResp {
  role: UserRole = UserRole.UNKNOWN;
  operatorCompanies: Array<{ companyId: number; companyName: string }> = [];
}

export interface RingInfoResp {
  versionInfo: any;
  webSettings: any;
  isGovCloud: boolean;
  supportEndpoint: string;
  communityEndpoint: string;
}
