import { ErrorPageComponent } from './core/error-page/error-page.component';
import { LoginComponent } from './core/login/login.component';
import { IsAppInitGuard } from './core/is-app-init.guard';
import { O365GeoSelectionComponent } from './dashboard/o365-geo-selection/o365-geo-selection.component';
import { ExternalRedirectGuard } from './core/external-redirect.guard';
import { PageLoadingComponent } from './core/page-loading/page-loading.component';
import { EndpointGeoSelectionComponent } from './dashboard/endpoint-geo-selection/endpoint-geo-selection.component';
import { ContactSupportComponent } from './core/contact-support/contact-support.component';
import { SupportNavGuard } from './core/support-nav.guard';
const ɵ0 = { isPublicUrl: true }, ɵ1 = { isPublicUrl: true }, ɵ2 = () => import("./support/support.module.ngfactory").then((m) => m.SupportModuleNgFactory), ɵ3 = () => import("./status/status.module.ngfactory").then((m) => m.StatusModuleNgFactory), ɵ4 = () => import("./dashboard/dashboard.module.ngfactory").then((m) => m.DashboardModuleNgFactory), ɵ5 = () => import("./backup-config/backup-config.module.ngfactory").then((m) => m.BackupConfigModuleNgFactory);
export const routes = [
    { path: 'login', component: LoginComponent },
    { path: 'customer-support', component: ContactSupportComponent, data: ɵ0 },
    { path: 'error', component: ErrorPageComponent, data: ɵ1 },
    {
        path: 'support',
        loadChildren: ɵ2,
        canActivate: [SupportNavGuard]
    },
    {
        path: 'status',
        loadChildren: ɵ3,
        canActivate: [IsAppInitGuard]
    },
    {
        path: 'dashboard',
        loadChildren: ɵ4,
        canActivate: [IsAppInitGuard]
    },
    {
        path: 'backup-config',
        loadChildren: ɵ5,
        canActivate: [IsAppInitGuard]
    },
    {
        path: 'multi-geo/o365/:type',
        component: O365GeoSelectionComponent,
        canActivate: [IsAppInitGuard]
    },
    {
        path: 'multi-geo/endpoint',
        component: EndpointGeoSelectionComponent,
        canActivate: [IsAppInitGuard]
    },
    {
        path: 'ac-redirect',
        canActivate: [ExternalRedirectGuard],
        component: PageLoadingComponent
    },
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
];
const ɵ6 = () => import("./multi-commcell/multi-commcell.module.ngfactory").then((m) => m.MultiCommcellModuleNgFactory), ɵ7 = { isPublicUrl: true };
// Multi commcell login routes
export const mcRoutes = [
    {
        path: '',
        loadChildren: ɵ6,
        data: ɵ7
    },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
