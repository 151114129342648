import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { User } from '../datamodel/user';

declare let aptrinsic: any;

@Injectable({
  providedIn: 'root'
})
export class GainsightService {
  constructor(@Inject(DOCUMENT) private document: any) {}

  loadGainSight(apiKey: string, user: User) {
    const a = 'https://web-sdk.aptrinsic.com/api/aptrinsic.js';
    const i = 'aptrinsic';
    // tslint:disable: only-arrow-functions
    (window[i] =
      window[i] ||
      function () {
        (window[i].q = window[i].q || []).push(arguments);
      }),
      (window[i].p = apiKey);
    const r = this.document.createElement('script');
    r.async = !0;
    r.src = a + '?a=' + apiKey;
    const c = this.document.getElementsByTagName('script')[0];
    c.parentNode.insertBefore(r, c);
    this.initGainSight(user);
  }

  initGainSight(user: User) {
    aptrinsic(
      'identify',
      {
        // User Fields
        id: user.userGuid, // Required for logged in app users
        email: user.email,
        name: user.userName
      },
      {
        // Account Fields
        id: user.companyGuid, // Required
        name: user.companyName,
        ring_id: window.location.host
      }
    );
  }
}
