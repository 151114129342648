import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

// #Copied
// https://stackblitz.com/edit/angular-labs-copy-clipboard?file=src%2Fapp%2Fshared%2Fcopy-clipboard.directive.ts
@Directive({ selector: '[copyClipboard]' })
export class CopyClipboardDirective {
  @Input('copyClipboard')
  public payload: string;
  @Input() public displayCopyIcon = false;
  public initialInnerHTML: any;

  @Output()
  public copied: EventEmitter<string> = new EventEmitter<string>();

  constructor(private el: ElementRef) {
    setTimeout(() => {
      const copyIconElement = this.displayCopyIcon ? `<img class="pr-2" src="assets/svg/icons/icon-copy.svg">` : '';
      const displayText = el.nativeElement.innerHTML || 'Copy';
      // tslint:disable-next-line: max-line-length
      this.initialInnerHTML = `<span class="d-flex justify-content-center align-items-center">${copyIconElement}${displayText}</span>`;
      el.nativeElement.innerHTML = this.initialInnerHTML;
    }, 0);
  }

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    event.preventDefault();
    if (!this.payload) {
      return;
    }

    const listener = (e: ClipboardEvent) => {
      // tslint:disable-next-line: no-string-literal
      const clipboard = e.clipboardData || window['clipboardData'];
      clipboard.setData('text', (this.payload || '').toString());
      e.preventDefault();
      this.el.nativeElement.innerHTML = 'Copied!';
      this.copied.emit(this.payload || '');
      this.reset();
    };

    document.addEventListener('copy', listener, false);
    document.execCommand('copy');
    document.removeEventListener('copy', listener, false);
  }

  reset() {
    // reset the text after 5 seconds
    setTimeout(() => {
      this.el.nativeElement.innerHTML = this.initialInnerHTML;
    }, 5000);
  }
}
