import { Routes } from '@angular/router';
import { ErrorPageComponent } from './core/error-page/error-page.component';
import { LoginComponent } from './core/login/login.component';
import { IsAppInitGuard } from './core/is-app-init.guard';
import { O365GeoSelectionComponent } from './dashboard/o365-geo-selection/o365-geo-selection.component';
import { ExternalRedirectGuard } from './core/external-redirect.guard';
import { PageLoadingComponent } from './core/page-loading/page-loading.component';
import { EndpointGeoSelectionComponent } from './dashboard/endpoint-geo-selection/endpoint-geo-selection.component';
import { ContactSupportComponent } from './core/contact-support/contact-support.component';
import { SupportNavGuard } from './core/support-nav.guard';

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'customer-support', component: ContactSupportComponent, data: { isPublicUrl: true } },
  { path: 'error', component: ErrorPageComponent, data: { isPublicUrl: true } },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then((m) => m.SupportModule),
    canActivate: [SupportNavGuard]
  },
  {
    path: 'status',
    loadChildren: () => import('./status/status.module').then((m) => m.StatusModule),
    canActivate: [IsAppInitGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [IsAppInitGuard]
  },
  {
    path: 'backup-config',
    loadChildren: () => import('./backup-config/backup-config.module').then((m) => m.BackupConfigModule),
    canActivate: [IsAppInitGuard]
  },
  {
    path: 'multi-geo/o365/:type',
    component: O365GeoSelectionComponent,
    canActivate: [IsAppInitGuard]
  },
  {
    path: 'multi-geo/endpoint',
    component: EndpointGeoSelectionComponent,
    canActivate: [IsAppInitGuard]
  },
  {
    path: 'ac-redirect',
    canActivate: [ExternalRedirectGuard],
    component: PageLoadingComponent
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
];

// Multi commcell login routes
export const mcRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./multi-commcell/multi-commcell.module').then((m) => m.MultiCommcellModule),
    data: { isPublicUrl: true }
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];
