import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
export class GainsightService {
    constructor(document) {
        this.document = document;
    }
    loadGainSight(apiKey, user) {
        const a = 'https://web-sdk.aptrinsic.com/api/aptrinsic.js';
        const i = 'aptrinsic';
        // tslint:disable: only-arrow-functions
        (window[i] =
            window[i] ||
                function () {
                    (window[i].q = window[i].q || []).push(arguments);
                }),
            (window[i].p = apiKey);
        const r = this.document.createElement('script');
        r.async = !0;
        r.src = a + '?a=' + apiKey;
        const c = this.document.getElementsByTagName('script')[0];
        c.parentNode.insertBefore(r, c);
        this.initGainSight(user);
    }
    initGainSight(user) {
        aptrinsic('identify', {
            // User Fields
            id: user.userGuid,
            email: user.email,
            name: user.userName
        }, {
            // Account Fields
            id: user.companyGuid,
            name: user.companyName,
            ring_id: window.location.host
        });
    }
}
GainsightService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GainsightService_Factory() { return new GainsightService(i0.ɵɵinject(i1.DOCUMENT)); }, token: GainsightService, providedIn: "root" });
