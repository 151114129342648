import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from '../chat.service';

@Component({
  selector: 'offline-chat',
  templateUrl: './offline-chat.component.html',
  styleUrls: ['./offline-chat.component.scss']
})
export class OfflineChatComponent implements OnInit {
  constructor(private router: Router, public chatService: ChatService) {}

  createCase() {
    this.router.navigate(['/support'], { queryParams: { action: 'create' } });
    this.chatService.showOfflinePopup = false;
  }

  closeChatPopup() {
    this.chatService.showOfflinePopup = false;
    this.chatService.showOfflineButton();
  }

  ngOnInit() {}
}
