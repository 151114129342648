import { Component, OnInit, ViewChild } from '@angular/core';
import { PageMaskService } from './page-mask.service';
import { ModalDirective } from 'ng-uikit-pro-standard';

@Component({
  selector: 'page-mask',
  templateUrl: './page-mask.component.html',
  styleUrls: ['./page-mask.component.scss']
})
export class PageMaskComponent implements OnInit {
  progressMsg = '';
  @ViewChild('maskModal', { static: true }) maskModal: ModalDirective;
  constructor(private pageMaskService: PageMaskService) {}

  ngOnInit() {
    this.pageMaskService.loadingSubject.subscribe((loading: boolean) => {
      if (loading) {
        this.maskModal.show();
      } else {
        this.maskModal.hide();
      }
    });
    this.pageMaskService.progressMsgSubject.subscribe((msg: string) => (this.progressMsg = msg));
  }
}
