export const environment = {
  production: false,
  oem: 'metallic',
  authUrl: 'https://dlogin.commvault.com/api/',
  loginUrl: '/webconsole/login/index.jsp',
  logoutUrl: '/webconsole/doLogout.do',
  productFnApiBaseUrl: '/api/core/',
  bizSysFnApiBaseUrl: '/api/biz/',
  productUrl: '/commandcenter/',
  ccApiUrl: '/commandcenter/api/',
  complianceSearchUrl: '/commandcenter/#/complianceSearch',
  redirectDashboardToProduct: false,
  docUrl: 'https://docs.metallic.io/metallic/',
  appTitle: 'Login',
  pollingInterval: 10000, // 10 seconds
  jobPollAttempts: 120,
  beaconPollAttempts: 6,
  multiCommcellDeployment: true,
  webConsoleSummaryUrl: '/webconsole/summary/index.do',
  marketingBaseUrl: 'https://metallic.io/',
  cvMarketingBaseUrl: 'https://www.commvault.com/',
  currentTimeStamp: '1719215279355'
};
