import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class PageMaskService {
    constructor() {
        this.loadingSubject = new BehaviorSubject(false);
        this.progressMsgSubject = new BehaviorSubject('');
    }
    mask(msg) {
        this.loadingSubject.next(true);
        if (msg) {
            this.progressMsgSubject.next(msg);
        }
    }
    unmask() {
        this.loadingSubject.next(false);
        this.progressMsgSubject.next('');
    }
    setProgressMsg(msg) {
        this.progressMsgSubject.next(msg);
    }
}
PageMaskService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PageMaskService_Factory() { return new PageMaskService(); }, token: PageMaskService, providedIn: "root" });
