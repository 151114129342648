/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./offline-chat.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ng-uikit-pro-standard/ng-uikit-pro-standard.ngfactory";
import * as i3 from "ng-uikit-pro-standard";
import * as i4 from "@angular/common";
import * as i5 from "./offline-chat.component";
import * as i6 from "@angular/router";
import * as i7 from "../chat.service";
var styles_OfflineChatComponent = [i0.styles];
var RenderType_OfflineChatComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OfflineChatComponent, data: {} });
export { RenderType_OfflineChatComponent as RenderType_OfflineChatComponent };
function View_OfflineChatComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "chat-popup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "popup-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contact Us"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "mdb-icon", [["class", "close-icon"], ["fas", ""], ["icon", "times"], ["size", "md"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeChatPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MdbIconComponent_0, i2.RenderType_MdbIconComponent)), i1.ɵdid(5, 114688, null, 0, i3.MdbIconComponent, [i1.ElementRef, i1.Renderer2], { icon: [0, "icon"], size: [1, "size"], class: [2, "class"] }, null), i1.ɵdid(6, 16384, null, 0, i3.FasDirective, [i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "offline-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Our Support Team is offline"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please create a case and an engineer will get back to you soon"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["block", "true"], ["color", "secondary"], ["mdbBtn", ""], ["mdbWavesEffect", ""], ["size", "md"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).click($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.createCase() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MdbBtnDirective_0, i2.RenderType_MdbBtnDirective)), i1.ɵdid(13, 638976, null, 0, i3.MdbBtnDirective, [i1.ElementRef, i1.Renderer2], { color: [0, "color"], size: [1, "size"], block: [2, "block"] }, null), i1.ɵdid(14, 16384, null, 0, i3.WavesDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Create a Case"]))], function (_ck, _v) { var currVal_0 = "times"; var currVal_1 = "md"; var currVal_2 = "close-icon"; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "secondary"; var currVal_4 = "md"; var currVal_5 = "true"; _ck(_v, 13, 0, currVal_3, currVal_4, currVal_5); }, null); }
export function View_OfflineChatComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OfflineChatComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chatService.showOfflinePopup; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_OfflineChatComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "offline-chat", [], null, null, null, View_OfflineChatComponent_0, RenderType_OfflineChatComponent)), i1.ɵdid(1, 114688, null, 0, i5.OfflineChatComponent, [i6.Router, i7.ChatService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OfflineChatComponentNgFactory = i1.ɵccf("offline-chat", i5.OfflineChatComponent, View_OfflineChatComponent_Host_0, {}, {}, []);
export { OfflineChatComponentNgFactory as OfflineChatComponentNgFactory };
