export enum ApiStates {
  NEW = 'new',
  ACTIVE = 'active',
  CHECKING = 'checking',
  COMPLETED = 'completed',
  FAILED = 'failed'
}

export enum StorageCopyType {
  CLOUD = 1,
  ONPREM = 2,
  VENDOR = 3
}

export enum CloudStorageVendorType {
  AMAZON = 2,
  AZURE = 3
}

export enum CloudProviderType {
  OCI = 'oci',
  AWS = 'aws'
}

export enum GatewayHosts {
  DEFAULT = 0,
  VMWARE = 1,
  HyperV = 2,
  AWS = 16,
  AZURE = 32,
  GOOGLE = 64,
  OCI = 128,
  KUBERNETES = 512
}
