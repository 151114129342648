<div *ngIf="chatService.showOfflinePopup" class="chat-popup">
    <div class="popup-header">
        <span i18n>Contact Us</span>
        <mdb-icon size="md" class="close-icon" (click)="closeChatPopup()" fas icon="times"></mdb-icon>
    </div>
    <div class="offline-message">
       <h5 i18n>CopyText:agent-not-available</h5>
       <p i18n>CopyText:agent-not-available-instructions</p>
    </div>
    <button type="button" color="secondary" size="md" block="true" mdbBtn (click)="createCase()" mdbWavesEffect>Create a Case</button>
 </div>