import { Pipe, PipeTransform } from '@angular/core';

export enum SizeUnit {
  BYTES = 'BYTES',
  KB = 'KB',
  MB = 'MB',
  GB = 'GB',
  TB = 'TB',
  PB = 'PB',
  EB = 'EB',
  ZB = 'ZB',
  YB = 'YB',
  RELATIVE = 'relative'
}

@Pipe({
  name: 'size'
})
export class SizePipe implements PipeTransform {
  sizeUnits = [
    SizeUnit.BYTES,
    SizeUnit.KB,
    SizeUnit.MB,
    SizeUnit.GB,
    SizeUnit.TB,
    SizeUnit.PB,
    SizeUnit.EB,
    SizeUnit.ZB,
    SizeUnit.YB
  ];
  oneKB = 1024;

  transform(
    value: any,
    srcUnit: SizeUnit = SizeUnit.BYTES,
    destUnit: SizeUnit = SizeUnit.RELATIVE,
    precision: number = 2,
    returnAsHtml: boolean = false
  ): any {
    if (value === undefined || value === null) {
      return 0;
    }
    value = Number(value);
    let retVal = value;
    let retUnit;
    retUnit = destUnit;

    if (srcUnit !== destUnit) {
      if (destUnit === SizeUnit.RELATIVE) {
        let i = this.sizeUnits.indexOf(srcUnit);
        if (value > 0 && value < 1) {
          do {
            value = value * this.oneKB;
            i--;
          } while (value < 1);
        } else {
          while (value >= this.oneKB) {
            value = value / this.oneKB;
            i++;
          }
        }
        retVal = Math.max(value, 0).toFixed(precision);
        retUnit = this.sizeUnits[i];
      } else {
        // diff determines how many times we would have to divide or
        // multiply the source by 1024
        const diff = this.sizeUnits.indexOf(destUnit) - this.sizeUnits.indexOf(srcUnit);
        if (diff < 0) {
          value = value * Math.pow(this.oneKB, Math.abs(diff));
        } else {
          value = value / Math.pow(this.oneKB, Math.abs(diff));
        }
        retVal = Math.max(value, 0).toFixed(precision);
      }
    } else {
      retVal = value.toFixed(precision);
    }
    if (returnAsHtml) {
      return `<span class='size-value'>${retVal}</span> <span class='size-unit'>${retUnit}</span>`;
    } else {
      return retVal + ' ' + retUnit;
    }
  }
}
