import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'card-menu-option',
  templateUrl: './card-menu-option.component.html',
  styleUrls: ['./card-menu-option.component.scss']
})
export class CardMenuOptionComponent implements OnInit {
  @Input() menuConfig: Array<MenuOption>;
  @Output() menuSelected = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  menuOptionClicked(event: MouseEvent, selectedMenu: string) {
    event.preventDefault();
    this.menuSelected.emit(selectedMenu);
  }
}

export class MenuOption {
  name: string;
  displayName: string;

  constructor(name: string, displayName: string) {
    this.name = name;
    this.displayName = displayName;
  }
}
