import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { User, UserWrapper } from '../datamodel/user';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { BrandingService } from '../branding/branding.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: User;
  avatarBgColor = '#794de8';
  homeUrl = environment.productUrl;
  isReferrerExternal = false;
  logoSrc = '';

  constructor(
    private authService: AuthService,
    private activeRoute: ActivatedRoute,
    private brandingService: BrandingService,
    @Inject(DOCUMENT) private dom: HTMLDocument
  ) {}

  ngOnInit() {
    this.authService.currentUserSubject.subscribe((userWrapper: UserWrapper) => {
      if (userWrapper && userWrapper.isMetaDataResolved) {
        this.user = userWrapper.user;
      }
    });
    this.brandingService.themeSubject.subscribe((theme: any) => {
      if (theme && theme.isResolved) {
        this.logoSrc = theme.logoPath;
        this.dom.getElementById('appFavicon').setAttribute('href', theme.iconPath);
      }
    });
    this.activeRoute.queryParams.subscribe((queryParams) => {
      const referrer = queryParams.ref;
      if (referrer === 'CC') {
        this.isReferrerExternal = true;
      }
    });
  }

  ngOnDestroy() {}

  logout(e: Event) {
    e.preventDefault();
    this.authService.logout();
  }
}
