<app-header *ngIf="user && isAppInitialized"></app-header>
<app-translations></app-translations>
<main>
  <div class="container mt-3">
    <page-loading *ngIf="!appRouter.activatedRouteData.isPublicUrl && !(user && isAppInitialized)"></page-loading>
    <router-outlet #appRouter="outlet"></router-outlet>
  </div>
</main>
<page-mask></page-mask>
<offline-chat></offline-chat>
