<div #maskModal="mdbModal" class="mt-modal modal fade mask-modal" mdbModal role="dialog" tabindex="-1"
  [config]="{keyboard:false,backdrop:'static'}">
  <div class="modal-dialog modal-dialog-scrollable modal-md" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="loading-image">
          <mdb-spinner spinnerType="big"></mdb-spinner>
        </div>
        <div class="loading-text" [innerHtml]="progressMsg"></div>
      </div>
    </div>
  </div>
</div>
