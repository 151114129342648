import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConstantService } from './core/constant.service';
import { UserMappingResp, UserRole } from './core/datamodel/user';
import { get } from 'lodash';
import { AuthService } from './core/auth/auth.service';
import { Router } from '@angular/router';
import { LoggingService } from './logging/logging.service';
import { FeatureType } from './core/datamodel/offering';
import { AdditionalSetting, UIFeatureType } from './core/ui-models';
import { ChatService } from './core/chat/chat.service';
import { PendoService } from './core/pendo/pendo.service';
import { GainsightService } from './core/gainsight/gainsight.service';
import { BrandingService } from './core/branding/branding.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
import * as i3 from "./core/constant.service";
import * as i4 from "./core/auth/auth.service";
import * as i5 from "./logging/logging.service";
import * as i6 from "./core/chat/chat.service";
import * as i7 from "./core/pendo/pendo.service";
import * as i8 from "./core/gainsight/gainsight.service";
import * as i9 from "./core/branding/branding.service";
export class AppService {
    constructor(router, http, constants, authService, loggingService, chatService, pendoService, gainsightService, brandingService) {
        this.router = router;
        this.http = http;
        this.constants = constants;
        this.authService = authService;
        this.loggingService = loggingService;
        this.chatService = chatService;
        this.pendoService = pendoService;
        this.gainsightService = gainsightService;
        this.brandingService = brandingService;
        this.isInitialized = false;
        this.isInitializedSubject = new BehaviorSubject(this.isInitialized);
        this.isNavResolvedSubject = new BehaviorSubject(false);
        this.spVersion = 0;
        this.isGovCloud = false;
        this.supportEndpointUrl = '';
        this.communityEndpointUrl = '';
        this.featureVisibility = {};
        this.tenantSettings = new Map();
        this.offeringsOverride = [];
        this.baseUrl = environment.productFnApiBaseUrl;
        this.migrateToCCFeatureSupportMap = {}; // stores FeatureType | Offering(as a key)
    }
    init(companyIdFromUrl) {
        const init = (observer) => {
            const initSteps = [this.getLoggedInUserRole(), this.getRingInfoAndWebSettings(), this.getCustomization()];
            forkJoin(initSteps).subscribe(([userMappingResp, ringInfo, customization]) => {
                this.loggingService.logDebug('ring-info API response', ringInfo);
                if (ringInfo) {
                    this.isGovCloud = ringInfo.isGovCloud;
                    this.supportEndpointUrl = ringInfo.supportEndpoint;
                    this.communityEndpointUrl = ringInfo.communityEndpoint;
                    if (ringInfo.versionInfo) {
                        // tslint:disable-next-line: no-string-literal
                        window['versions'] = ringInfo.versionInfo;
                    }
                    if (ringInfo.webSettings) {
                        if (ringInfo.webSettings.loggingKey) {
                            this.loggingService.update(ringInfo.webSettings.loggingLevel, ringInfo.webSettings.loggingKey);
                        }
                        // log the user role response after logging service is initialized with app insights key
                        this.loggingService.logInfo('getusermappings response', this.userRoleRespLog);
                        if (ringInfo.webSettings.featureVisibility) {
                            try {
                                this.featureVisibility = JSON.parse(ringInfo.webSettings.featureVisibility);
                            }
                            catch (e) {
                                this.loggingService.logException('Failed to parse feature visibility JSON', e);
                            }
                            this.loggingService.logDebug('feature-visibility response', this.featureVisibility);
                        }
                        if (ringInfo.webSettings.offeringsOverride) {
                            try {
                                this.offeringsOverride = JSON.parse(ringInfo.webSettings.offeringsOverride);
                            }
                            catch (e) {
                                this.loggingService.logException('Failed to parse offerings override JSON', e);
                            }
                            this.loggingService.logDebug('offerings-override response', this.offeringsOverride);
                        }
                    }
                }
                if (this.isFeatureFlagSet(UIFeatureType.USE_CC_THEME)) {
                    this.brandingService.init(customization);
                }
                else {
                    this.brandingService.resolveTheme();
                }
                const userRole = userMappingResp.role;
                this.authService.setUserRole(userRole);
                const getTenantSettingsAndInitApp = () => {
                    // Get Tenant level addtional settings. This is required to control feature visibility
                    // So, this needs to be invoked before initializing the app and
                    // after setting the Company ID from URL (for MSP use case)
                    this.getTenantSettingsAndInitApp(ringInfo, userRole).subscribe((flag) => {
                        observer.next(flag);
                        observer.complete();
                    }, () => {
                        observer.next(false);
                        observer.complete();
                    });
                };
                if (userRole === UserRole.MSP_ADMIN || userRole === UserRole.MSP_USER || userRole === UserRole.UNKNOWN) {
                    this.redirectToAdminconsole();
                    observer.next(false);
                    observer.complete();
                }
                else if (
                // tslint:disable-next-line: no-string-literal
                !window.location.href.includes('dashboard/endpoint/download') &&
                    userRole === UserRole.TENANT_USER) {
                    const completeInitCall = () => {
                        if (!observer.closed && !this.isRedirectToWebconsoleDisabledForTenantUser()) {
                            observer.next(false);
                            observer.complete();
                        }
                    };
                    forkJoin([this.authService.isLaptopOwner(), this.authService.isO365SelfServiceEnabled()]).subscribe(([isLaptopOwner, isO365SelfServiceEnabled]) => {
                        if (isLaptopOwner) {
                            this.redirectToLaptopsEndUserModePage();
                        }
                        else if (isO365SelfServiceEnabled) {
                            this.redirectToO365EndUserModePage();
                        }
                        else if (this.isRedirectToWebconsoleDisabledForTenantUser()) {
                            getTenantSettingsAndInitApp();
                        }
                        else {
                            this.redirectToAdminconsole();
                        }
                        completeInitCall();
                    });
                    completeInitCall();
                }
                else {
                    getTenantSettingsAndInitApp();
                }
            });
        };
        this.companyId = companyIdFromUrl || this.authService.companyId;
        return new Observable((observer) => {
            if (companyIdFromUrl) {
                this.getCompanyName(companyIdFromUrl).subscribe((companyName) => {
                    if (companyName) {
                        const companyObj = { companyId: Number(companyIdFromUrl), companyName };
                        this.loggingService.logInfo('Overwriting company details with ', companyObj);
                        this.authService.overwriteCompanyDetails(companyObj);
                    }
                    else {
                        // TODO: Check with UX for error notification
                        this.loggingService.logException(`Logged-in user does not have permission on company ID: ${companyIdFromUrl}`, {});
                        this.loggingService.logInfo(`Application will be loaded with logged-in user's company ID: ${this.authService.companyId}`);
                        // change the companyId to initial one
                        this.companyId = this.authService.companyId;
                    }
                    init(observer);
                });
            }
            else {
                init(observer);
            }
        });
    }
    getTenantSettingsAndInitApp(ringInfo, userRole) {
        return this.getTenantSettings().pipe(map((settings) => {
            settings.forEach((s) => {
                this.tenantSettings.set(s.attrName, s.atterValue);
            });
            this.loggingService.logInfo('Tenant level additional settings: ', Object.fromEntries(this.tenantSettings));
            this.setMigrateToCCFeatureSupportMap(this.getTenantSetting(AdditionalSetting.MIGRATE_TO_CC));
            this.isInitialized = true;
            this.isInitializedSubject.next(this.isInitialized);
            this.authService.updateMetadata();
            this.authService.currentUserSubject.subscribe((userWrapper) => {
                this.userWrapper = userWrapper;
                if (userWrapper && userWrapper.isMetaDataResolved) {
                    if (!environment.multiCommcellDeployment &&
                        !this.isFeatureFlagSet(UIFeatureType.HIDE_CHAT) &&
                        !userWrapper.isMSPTenant) {
                        this.chatService.initChat(userWrapper.user);
                    }
                    if (ringInfo && ringInfo.webSettings && ringInfo.webSettings.userAnalytics) {
                        const userAnalytics = JSON.parse(ringInfo.webSettings.userAnalytics);
                        if (userAnalytics.analytics === this.constants.analytics.pendo) {
                            this.pendoService.loadPendo(userAnalytics.pendo, userWrapper);
                        }
                        else if (userAnalytics.analytics === this.constants.analytics.gainsight) {
                            this.gainsightService.loadGainSight(userAnalytics.gainsight, userWrapper.user);
                        }
                    }
                }
            });
            // this will be called parallely and doesn't require spinner
            this.getNavSettings(userRole).subscribe((includedNavItems) => {
                this.authService.setNavSettings(includedNavItems);
                this.isNavResolvedSubject.next(true);
            });
            return true;
        }));
    }
    redirectToWebconsole() {
        this.router.navigate(['/ac-redirect'], {
            state: { url: environment.webConsoleSummaryUrl },
            skipLocationChange: true
        });
    }
    redirectToAdminconsole() {
        this.router.navigate(['/ac-redirect'], {
            state: { url: environment.productUrl },
            skipLocationChange: true
        });
    }
    redirectToO365EndUserModePage() {
        this.router.navigate(['/ac-redirect'], {
            state: { url: `${environment.productUrl}?mode=eu#/office365/overview` },
            skipLocationChange: true
        });
    }
    redirectToLaptopsEndUserModePage() {
        this.router.navigate(['/ac-redirect'], {
            state: { url: `${environment.productUrl}?mode=eu#/devices` },
            skipLocationChange: true
        });
    }
    getRingInfoAndWebSettings() {
        return this.http
            .get(`${this.baseUrl}${this.constants.apis.ringInfo}`)
            .pipe(map((resp) => {
            return resp;
        }))
            .pipe(catchError((err) => {
            if (err && err.status !== 401 && err.status !== 403) {
                this.router.navigate(['error']);
            }
            return of([]);
        }));
    }
    getCompanyName(companyId) {
        return this.http
            .get(
        // tslint:disable-next-line : max-line-length
        `${environment.productFnApiBaseUrl}${this.constants.apis.organization}?fq=companyId:eq:${companyId}&fl=providers.connectName,providers.provider`, { headers: { operatorCompanyId: companyId } })
            .pipe(map((resp) => {
            if (resp.providers && resp.providers[0] && resp.providers[0].connectName) {
                return resp.providers[0].connectName;
            }
            return undefined;
        }), catchError(() => {
            return of(undefined);
        }));
    }
    getCustomization() {
        return this.http
            .get(`${environment.productFnApiBaseUrl}${this.constants.apis.organization}/${this.companyId}/customization`)
            .pipe(map((resp) => {
            return resp;
        }))
            .pipe(catchError((err) => {
            if (err && err.status !== 401 && err.status !== 403) {
                this.router.navigate(['error']);
            }
            return of([]);
        }));
    }
    getNavSettings(userRole = this.userWrapper.role) {
        return this.http
            .get(`${environment.productFnApiBaseUrl}${this.constants.apis.organization}/${this.companyId}/navigation`)
            .pipe(map((resp) => {
            if (resp.error && resp.error.errorCode === 0) {
                if (resp.navSettings && resp.navSettings.companySettings && resp.navSettings.companySettings.length) {
                    const companySettingsForLoginUserRole = resp.navSettings.companySettings.find((settings) => settings.userRole === userRole) || {};
                    const includedNavItemsBasedOnSolutionType = {};
                    const includedNavItemsFromResp = {}; // for quicker look up
                    (companySettingsForLoginUserRole.includeNavItems || '').split(',').forEach((navItem) => {
                        includedNavItemsFromResp[navItem] = true;
                        // initializing by default as true
                        includedNavItemsBasedOnSolutionType[this.constants.navKeysSolutionTypeMap[navItem]] = true;
                    });
                    Object.keys(this.constants.navKeysSolutionTypeMap).forEach((navItem) => {
                        if (!includedNavItemsFromResp[navItem]) {
                            // any one in the list is missing setting as false
                            includedNavItemsBasedOnSolutionType[this.constants.navKeysSolutionTypeMap[navItem]] = false;
                        }
                    });
                    return includedNavItemsBasedOnSolutionType;
                }
                else {
                    this.loggingService.logInfo(`nav settings is empty for company ${this.companyId}`, resp);
                    return {};
                }
            }
            this.loggingService.logException(`Failed to get nav settings for company ${this.companyId}`, resp);
            return {};
        }))
            .pipe(catchError((err) => {
            this.loggingService.logException(`Failed to get nav settings for company ${this.companyId}`, err);
            return of({});
        }));
    }
    getLoggedInUserRole() {
        const respToReturn = new UserMappingResp();
        return this.http
            .get(`${environment.productFnApiBaseUrl}${this.constants.apis.userMappings}`)
            .pipe(map((roleResp) => {
            this.userRoleRespLog = roleResp;
            if (roleResp.error_code === 0) {
                respToReturn.role = get(roleResp.data, 'userRole', UserRole.UNKNOWN);
                return respToReturn;
            }
            else {
                return respToReturn;
            }
        }))
            .pipe(catchError((err) => {
            if (err && err.status !== 401 && err.status !== 403) {
                this.userRoleRespLog = err;
                this.router.navigate(['error']);
            }
            return of(respToReturn);
        }));
    }
    isFeatureSupported(type) {
        return get(this.featureVisibility, type, false);
    }
    isFeatureFlagSet(type) {
        return this.featureVisibility && this.featureVisibility.hasOwnProperty(type) && this.featureVisibility[type];
    }
    isRedirectToWebconsoleDisabledForTenantUser() {
        return get(this.featureVisibility, UIFeatureType.REDIRECT_TENANT_USER_TO_HUB, false);
    }
    getOfferingsOverride() {
        return this.offeringsOverride;
    }
    getTenantSettings() {
        return this.http
            .get(`${this.baseUrl}${this.constants.apis.tenantSettings}`)
            .pipe(map((resp) => {
            return get(resp, 'data.consoleSettings', []);
        }))
            .pipe(catchError((err) => {
            this.loggingService.logException('Failed to load tenant level additional settings.', err);
            return of([]);
        }));
    }
    isTenantSettingsSet(setting) {
        const sVal = this.getTenantSetting(setting);
        return sVal && sVal.toLowerCase() === 'true';
    }
    getTenantSetting(setting) {
        return this.tenantSettings && this.tenantSettings.get(setting);
    }
    setMigrateToCCFeatureSupportMap(migrsteToCCSupportedFeatures) {
        (migrsteToCCSupportedFeatures || '').split(',').forEach((feature) => {
            this.migrateToCCFeatureSupportMap[feature] = true;
        });
        // for Azure DB we should navigate to CC as there was no implementation done in hub
        if (this.isFeatureSupported(FeatureType.Azure_DB) || this.isTenantSettingsSet(AdditionalSetting.CC_AZURE_DB)) {
            this.migrateToCCFeatureSupportMap[FeatureType.Azure_DB] = true;
        }
    }
    /**
     * Function to check whether the feature is supported for navigation to CC for configuration
     * @param feature - FeatureType | OfferingType
     * @returns - boolean
     */
    isMigrateToCCSupportedFeature(feature) {
        // For hybrid workloads, point to CC wizard by default
        if ([
            'aws-db',
            'aws-ec2',
            'azure-db',
            'azurevm',
            'fs',
            'hyperv',
            'kubernetes',
            'object-storage',
            'oci-os',
            'oci-vm',
            'oracle',
            'sap-hana',
            'sql',
            'vmware',
            'nutanix-ahv'
        ].includes(feature.toString())) {
            return true;
        }
        return this.migrateToCCFeatureSupportMap[feature];
    }
    getMirageDashboardURL() {
        return this.getTenantSetting(AdditionalSetting.MIRAGE_DASHBOARD_URL);
    }
}
AppService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppService_Factory() { return new AppService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.ConstantService), i0.ɵɵinject(i4.AuthService), i0.ɵɵinject(i5.LoggingService), i0.ɵɵinject(i6.ChatService), i0.ɵɵinject(i7.PendoService), i0.ɵɵinject(i8.GainsightService), i0.ɵɵinject(i9.BrandingService)); }, token: AppService, providedIn: "root" });
