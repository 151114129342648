import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { User } from '../datamodel/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private renderer: Renderer2;
  showOfflinePopup = false;
  agentAvailable = false;

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: any) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  initChat(user: User) {
    // tslint:disable: no-string-literal
    if (!window['embedded_svc']) {
      const s = document.createElement('script');
      s.setAttribute('src', 'https://service.force.com/embeddedservice/5.0/esw.min.js');
      s.onload = () => {
        this.initESW(null, user);
      };
      this.renderer.appendChild(this.document.body, s);
    } else {
      this.initESW('https://service.force.com', user);
    }
  }

  showOfflineButton() {
    document.querySelector<HTMLElement>('.embeddedServiceHelpButton').style.display = 'block';
  }

  initESW(gslbBaseURL: any, user: User) {
    window['embedded_svc'].settings.displayHelpButton = true;
    window['embedded_svc'].settings.language = ''; // For example, enter 'en' or 'en-US'
    window['embedded_svc'].settings.enabledFeatures = ['LiveAgent'];
    window['embedded_svc'].settings.entryFeature = 'LiveAgent';
    window['embedded_svc'].settings.defaultMinimizedText = 'Metallic Support Chat';
    window['embedded_svc'].settings.disabledMinimizedText = 'Metallic Support Chat';
    window['embedded_svc'].settings.extraPrechatFormDetails = [
      {
        label: 'Email',
        transcriptFields: ['Email__c'],
        value: user.email,
        displayToAgent: false
      },
      {
        label: 'ContactName',
        transcriptFields: ['ContactName__c'],
        value: user.fullName,
        displayToAgent: false
      },
      {
        label: 'CompanyName',
        transcriptFields: ['CompanyName__c'],
        value: user.companyName,
        displayToAgent: false
      }
    ];

    window['embedded_svc'].settings.extraPrechatInfo = [
      {
        entityFieldMaps: [
          {
            doCreate: false,
            doFind: false,
            fieldName: 'Subject',
            isExactMatch: false,
            label: 'Subject'
          }
        ],
        entityName: 'Case'
      }
    ];

    const chatSettingsFile = window['embedded_svc'].snippetSettingsFile;
    if (chatSettingsFile && chatSettingsFile.extraPrechatFormDetails) {
      chatSettingsFile.extraPrechatFormDetails[0].value = user.email;
    }

    window['embedded_svc'].addEventHandler('onSettingsCallCompleted', (data: any) => {
      if (!data.isAgentAvailable) {
        const offlineButton = document.querySelector<HTMLElement>('.embeddedServiceHelpButton');
        if (offlineButton) {
          offlineButton.onclick = () => {
            if (!this.agentAvailable) {
              this.showOfflinePopup = true;
              offlineButton.style.display = 'none';
              this.agentAvailable = false;
            }
          };
        }
      }
      this.agentAvailable = data.isAgentAvailable;
    });

    window['embedded_svc'].addEventHandler('onAvailability', (data: any) => {
      this.agentAvailable = data.isAgentAvailable;
    });

    window['embedded_svc'].init(
      environment.production ? 'https://metallic.my.salesforce.com' : 'https://metallic--uat.my.salesforce.com',
      environment.production ? 'https://metallic.force.com' : 'https://uat-metallic.cs35.force.com',
      gslbBaseURL,
      environment.production ? '00D2E000001HCiu' : '00D2g0000000iry',
      'Metallic_Hub_Chat',
      {
        baseLiveAgentContentURL: environment.production
          ? 'https://c.la1-c2-ia2.salesforceliveagent.com/content'
          : 'https://c.la3-c1cs-ph2.salesforceliveagent.com/content',
        deploymentId: '5722E000000LSWO',
        buttonId: '5732E000000LTG2',
        baseLiveAgentURL: environment.production
          ? 'https://d.la1-c2-ia2.salesforceliveagent.com/chat'
          : 'https://d.la3-c1cs-ph2.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I2E000000Gp03UAC_16d93f5da8b',
        isOfflineSupportEnabled: false
      }
    );
  }
}
