/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-mask.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ng-uikit-pro-standard/ng-uikit-pro-standard.ngfactory";
import * as i3 from "ng-uikit-pro-standard";
import * as i4 from "./page-mask.component";
import * as i5 from "./page-mask.service";
var styles_PageMaskComponent = [i0.styles];
var RenderType_PageMaskComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageMaskComponent, data: {} });
export { RenderType_PageMaskComponent as RenderType_PageMaskComponent };
export function View_PageMaskComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { maskModal: 0 }), (_l()(), i1.ɵeld(1, 16777216, null, null, 9, "div", [["class", "mt-modal modal fade mask-modal"], ["mdbModal", ""], ["role", "dialog"], ["tabindex", "-1"]], null, [[null, "keydown"], [null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.esc" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).onEsc() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_ModalDirective_0, i2.RenderType_ModalDirective)), i1.ɵdid(2, 4374528, [[1, 4], ["maskModal", 4]], 0, i3.ModalDirective, [i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i3.ɵdj], { config: [0, "config"] }, null), i1.ɵpod(3, { keyboard: 0, backdrop: 1 }), (_l()(), i1.ɵeld(4, 0, null, 0, 6, "div", [["class", "modal-dialog modal-dialog-scrollable modal-md"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "loading-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "mdb-spinner", [["spinnerType", "big"]], null, null, null, i2.View_ProgressSpinnerComponent_0, i2.RenderType_ProgressSpinnerComponent)), i1.ɵdid(9, 4243456, null, 0, i3.ProgressSpinnerComponent, [i1.ElementRef, i1.PLATFORM_ID], { spinnerType: [0, "spinnerType"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "loading-text"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, false, "static"); _ck(_v, 2, 0, currVal_0); var currVal_1 = "big"; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.progressMsg; _ck(_v, 10, 0, currVal_2); }); }
export function View_PageMaskComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-mask", [], null, null, null, View_PageMaskComponent_0, RenderType_PageMaskComponent)), i1.ɵdid(1, 114688, null, 0, i4.PageMaskComponent, [i5.PageMaskService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageMaskComponentNgFactory = i1.ɵccf("page-mask", i4.PageMaskComponent, View_PageMaskComponent_Host_0, {}, {}, []);
export { PageMaskComponentNgFactory as PageMaskComponentNgFactory };
