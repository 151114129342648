export class SelectOption {
  value: any;
  label: string;
  icon?: string;
  disabled?: boolean;

  constructor(value: any, label: string, icon?: string, disabled?: boolean) {
    this.value = value;
    this.label = label;
    this.icon = icon;
    this.disabled = disabled;
  }
}

export class SystemFeedback {
  isSuccess: boolean;
  message: string;

  constructor(isSuccess: boolean, message: string) {
    this.isSuccess = isSuccess;
    this.message = message;
  }
}
export class Tab {
  name: string;
  icon: string;
  heading: string;

  constructor(name: string, heading: string, icon: string) {
    this.name = name;
    this.heading = heading;
    this.icon = icon;
  }
}

export enum UIFeatureType {
  HIDE_CHAT = 'hide-chat',
  REDIRECT_TENANT_USER_TO_HUB = 'redirect-tenant-user',
  HIDE_SUPPORT = 'hide-support',
  HIDE_STATUS = 'hide-status',
  USE_CC_THEME = 'use-cc-theme',
  ENABLE_USAGE_REPORT_V2 = 'enable-usage-report-v2',
  ENABLE_AZURE_VM_RUX = 'enable-azure-vm-rux',
  REMOVE_SUB_USAGE_TILE = 'remove-subscription-usage-tile',
  ENABLE_VM_PRICING_UPDATE = 'enable-vm-pricing-update',
  ENABLE_OCI_STORAGE_FOR_HYBRID_WORKLOADS = 'enable-oci-storage-for-hybrid',
  ENABLE_MRR_SUBSCRIPTION_CHECK = 'enable-mrr-subscription-check',
  ENABLE_O365_PRICING_UPDATE = 'enable-o365-pricing-update'
}

export enum AdditionalSetting {
  CC_AZURE_DB = 'showReactCloudDBPages',
  MIGRATE_TO_CC = 'migrateToCC',
  MIRAGE_DASHBOARD_URL = 'cvCloudConsoleURL',
  ENBALE_O365D365_REACT_MIGRATION = 'bShowOffice365ReactLink'
}
