/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-loading.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ng-uikit-pro-standard/ng-uikit-pro-standard.ngfactory";
import * as i3 from "ng-uikit-pro-standard";
import * as i4 from "./page-loading.component";
var styles_PageLoadingComponent = [i0.styles];
var RenderType_PageLoadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageLoadingComponent, data: {} });
export { RenderType_PageLoadingComponent as RenderType_PageLoadingComponent };
export function View_PageLoadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "page-spinner-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mdb-spinner", [["spinnerType", "big"]], null, null, null, i2.View_ProgressSpinnerComponent_0, i2.RenderType_ProgressSpinnerComponent)), i1.ɵdid(2, 4243456, null, 0, i3.ProgressSpinnerComponent, [i1.ElementRef, i1.PLATFORM_ID], { spinnerType: [0, "spinnerType"] }, null)], function (_ck, _v) { var currVal_0 = "big"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PageLoadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-loading", [], null, null, null, View_PageLoadingComponent_0, RenderType_PageLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i4.PageLoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageLoadingComponentNgFactory = i1.ɵccf("page-loading", i4.PageLoadingComponent, View_PageLoadingComponent_Host_0, {}, {}, []);
export { PageLoadingComponentNgFactory as PageLoadingComponentNgFactory };
