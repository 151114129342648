import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {
  private translations: any = {};

  constructor() {}

  set = (key: string, value: string) => {
    this.translations[key] = value;
  };

  get(key: string, ...args: string[]): string {
    let val = this.translations.hasOwnProperty(key) ? this.translations[key] : key;
    val = this.format(val, ...args);
    val = val.replace(/&amp;/g, '&');
    return val;
  }

  format = (str: string, ...args: string[]) =>
    str.replace(/\$(\d+)/g, (match, index) => (args && args[index]) || match);

  log = () => console.log(this.translations);
}
