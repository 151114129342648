import { HttpUrlEncodingCodec } from '@angular/common/http';
import { SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/common";
import * as i3 from "@angular/platform-browser";
export class BrandingService {
    constructor(httpCodec, document, sanitizer) {
        this.httpCodec = httpCodec;
        this.document = document;
        this.sanitizer = sanitizer;
        this.oem = environment.oem;
        this.currentTheme = {
            isResolved: false,
            logoPath: `assets/brand/${this.oem}/logo.svg`,
            iconPath: `assets/brand/${this.oem}/favicon.png`
        };
        this.themeSubject = new BehaviorSubject(this.currentTheme);
    }
    getFavIcon() {
        return this.currentTheme.iconPath;
    }
    init(customizaton) {
        if (customizaton &&
            customizaton.organizationInfo &&
            customizaton.organizationInfo.organizationProperties &&
            customizaton.organizationInfo.organizationProperties.customization) {
            this.customization = JSON.parse(customizaton.organizationInfo.organizationProperties.customization);
            this.setCustomTheme();
        }
        this.currentTheme.isResolved = true;
        this.themeSubject.next(this.currentTheme);
    }
    resolveTheme() {
        this.currentTheme.isResolved = true;
        this.themeSubject.next(this.currentTheme);
    }
    setCustomTheme() {
        const head = this.document.getElementsByTagName('head')[0];
        const customTheme = this.document.createElement('link');
        customTheme.rel = 'stylesheet';
        customTheme.href = 'assets/css/custom-color-theme.css?' + environment.currentTimeStamp;
        this.setIcons();
        this.setBackgroundColor();
        head.appendChild(customTheme);
        this.setCustomCss(head);
    }
    setIcons() {
        if (this.customization.customLogoSmall) {
            this.currentTheme.logoPath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/' + this.customization.customLogoSmall);
        }
        if (this.customization.customFavicon) {
            this.currentTheme.iconPath = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustResourceUrl('data:image/' + this.customization.customFavicon));
        }
    }
    setBackgroundColor() {
        if (this.customization.actionBtnBg && this.customization.actionBtnBg !== '') {
            this.document.documentElement.style.setProperty('--button-primary', this.customization.actionBtnBg);
        }
        if (this.customization.actionBtnText && this.customization.actionBtnText !== '') {
            this.document.documentElement.style.setProperty('--button-text', this.customization.actionBtnText);
        }
        if (this.customization.linkText && this.customization.actionBtnText !== '') {
            this.document.documentElement.style.setProperty('--link-text', this.customization.linkText);
        }
        if (this.customization.loginAndBannerBg && this.customization.loginAndBannerBg !== '') {
            this.document.documentElement.style.setProperty('--banner-background', this.customization.loginAndBannerBg);
        }
        if (this.customization.navBg && this.customization.navBg !== '') {
            this.document.documentElement.style.setProperty('--navigation-bar-background', this.customization.navBg);
        }
    }
    setCustomCss(head) {
        if (this.customization.customizeCSS && this.customization.customizeCSS !== '') {
            const customCssString = this.httpCodec.decodeValue(this.customization.customizeCSS);
            const style = document.createElement('style');
            style.id = 'custom-hub';
            style.textContent = customCssString;
            head.append(style);
        }
    }
}
BrandingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrandingService_Factory() { return new BrandingService(i0.ɵɵinject(i1.HttpUrlEncodingCodec), i0.ɵɵinject(i2.DOCUMENT), i0.ɵɵinject(i3.DomSanitizer)); }, token: BrandingService, providedIn: "root" });
