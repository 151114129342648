/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ngx-avatar/ngx-avatar.ngfactory";
import * as i3 from "ngx-avatar";
import * as i4 from "ng-uikit-pro-standard";
import * as i5 from "../../../../node_modules/ng-uikit-pro-standard/ng-uikit-pro-standard.ngfactory";
import * as i6 from "@angular/common";
import * as i7 from "@angular/router";
import * as i8 from "./header.component";
import * as i9 from "../auth/auth.service";
import * as i10 from "../branding/branding.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["aria-disabled", "true"], ["class", "nav-link disabled"], ["href", "#"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Company: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user.companyName; _ck(_v, 2, 0, currVal_0); }); }
function View_HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "dropdown-menu dropdown-menu-right dropdown"], ["role", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "d-flex flex-column p-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "d-flex flex-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "ngx-avatar", [["size", "70"]], null, null, null, i2.View_AvatarComponent_0, i2.RenderType_AvatarComponent)), i1.ɵdid(5, 704512, null, 0, i3.AvatarComponent, [i1.ElementRef, i3.ɵa, i3.AvatarService], { size: [0, "size"], bgColor: [1, "bgColor"], initials: [2, "initials"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 9, "div", [["class", "d-flex flex-column mt-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "user-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "user-company"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "user-email"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "a", [["class", "mt-2 text-right logout-link"], ["href", "#"], ["mdbWavesEffect", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).click($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.logout($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 16384, null, 0, i4.WavesDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, null, ["Log out"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "70"; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.avatarBgColor, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.user.fullName, ""); _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.user.fullName; _ck(_v, 8, 0, currVal_3); var currVal_4 = ((_co.user.initialCompanyId && (_co.user.initialCompanyId !== _co.user.companyId)) ? _co.user.initialCompanyName : _co.user.companyName); _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.user.email; _ck(_v, 12, 0, currVal_5); }); }
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 12, "li", [["class", "nav-item dropdown"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], null, null, i5.View_BsDropdownDirective_0, i5.RenderType_BsDropdownDirective)), i1.ɵprd(512, null, i4.BsDropdownState, i4.BsDropdownState, []), i1.ɵdid(2, 245760, null, 0, i4.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i4.ɵdj, i4.BsDropdownConfig, i4.BsDropdownState, i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 7, "a", [["class", "nav-link dropdown-toggle waves-light"], ["dropdownToggle", ""], ["mdbWavesEffect", ""], ["type", "button"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"], ["document", "click"], [null, "keyup.esc"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onDocumentClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("keyup.esc" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4).onEsc() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5).click($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 147456, null, 0, i4.BsDropdownToggleDirective, [i4.BsDropdownState, i1.ElementRef], null, null), i1.ɵdid(5, 16384, null, 0, i4.WavesDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "mdb-icon", [["fas", ""], ["icon", "user"]], null, null, null, i5.View_MdbIconComponent_0, i5.RenderType_MdbIconComponent)), i1.ɵdid(7, 114688, null, 0, i4.MdbIconComponent, [i1.ElementRef, i1.Renderer2], { icon: [0, "icon"] }, null), i1.ɵdid(8, 16384, null, 0, i4.FasDirective, [i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "span", [["class", "caret"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_HeaderComponent_3)), i1.ɵdid(12, 16384, null, 0, i4.BsDropdownMenuDirective, [i4.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_6 = "user"; _ck(_v, 7, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).isDropup; var currVal_1 = i1.ɵnov(_v, 2).isOpen; var currVal_2 = i1.ɵnov(_v, 2).isOpen; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v, 4).ariaHaspopup; var currVal_4 = i1.ɵnov(_v, 4).isDisabled; var currVal_5 = i1.ɵnov(_v, 4).isOpen; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); var currVal_7 = _co.user.userName; _ck(_v, 9, 0, currVal_7); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "mdb-navbar", [["SideClass", "navbar navbar-expand-lg"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_NavbarComponent_0, i5.RenderType_NavbarComponent)), i1.ɵdid(1, 6537216, null, 1, i4.NavbarComponent, [i1.Renderer2, i4.NavbarService, i1.ChangeDetectorRef, i1.NgZone, i6.DOCUMENT], { SideClass: [0, "SideClass"], containerInside: [1, "containerInside"] }, null), i1.ɵqud(603979776, 1, { links: 0 }), (_l()(), i1.ɵeld(3, 0, null, 0, 5, "mdb-navbar-brand", [], null, null, null, i5.View_LogoComponent_0, i5.RenderType_LogoComponent)), i1.ɵdid(4, 49152, null, 0, i4.LogoComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "a", [["class", "navbar-brand"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i6.LocationStrategy], { state: [0, "state"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(7, { url: 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", "Logo"], ["max-height", "40"], ["width", "180"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, 4, 7, "links", [], null, null, null, i5.View_LinksComponent_0, i5.RenderType_LinksComponent)), i1.ɵdid(10, 1097728, [[1, 4]], 1, i4.LinksComponent, [i4.NavbarService, i1.Renderer2], null, null), i1.ɵqud(603979776, 2, { links: 1 }), (_l()(), i1.ɵeld(12, 0, null, 0, 4, "ul", [["class", "navbar-nav ml-auto nav-flex-icons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(16, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "navbar navbar-expand-lg"; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = (_co.isReferrerExternal ? _ck(_v, 7, 0, _co.homeUrl) : i1.ɵEMPTY_MAP); var currVal_5 = (_co.isReferrerExternal ? "/ac-redirect" : "/"); _ck(_v, 6, 0, currVal_4, currVal_5); var currVal_7 = ((_co.user && _co.user.initialCompanyId) && (_co.user.initialCompanyId !== _co.user.companyId)); _ck(_v, 14, 0, currVal_7); var currVal_8 = _co.user; _ck(_v, 16, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 6).target; var currVal_3 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_6 = _co.logoSrc; _ck(_v, 8, 0, currVal_6); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i8.HeaderComponent, [i9.AuthService, i7.ActivatedRoute, i10.BrandingService, i6.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i8.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
