import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { get } from 'lodash';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { catchError, map } from 'rxjs/operators';
import { LoggingService } from '../logging/logging.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private loggingService: LoggingService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        // Only intercept responses
        if (event instanceof HttpResponse) {
          const errorCode = get(event.body, 'error_code', 0);
          const errorMessage = get(event.body, 'error_message', 'An error has occurred');

          if (errorCode && errorCode !== 0) {
            this.loggingService.logException(event.url, errorMessage, SeverityLevel.Critical);
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        const errorMessage = get(error, 'error.message');

        this.loggingService.logException(
          get(error, 'name', 'Unknown HTTP error'),
          errorMessage ? errorMessage : get(error, 'message', 'An error has occurred'),
          SeverityLevel.Critical
        );

        return throwError(error);
      })
    );
  }
}

